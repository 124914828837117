import { useRecoilState, useRecoilValue } from "recoil";
import instance from "helpers/instance";
import { listTheaterPassReportAtom, listTicketboxReportAtom } from "state/report";
import { authAtom } from "state/recoil-root";

const useReportActions = () => {
    const [auth, setAuth] = useRecoilState(authAtom);
    const headers = {
        Authorization: `Bearer ${auth?.access_token}`,
    };
    const [
        listTheaterPassReport,
        setListTheaterPassReport,
    ] = useRecoilState(listTheaterPassReportAtom);
    const [
        listTicketboxReport,
        setListTicketboxReport,
    ] = useRecoilState(listTicketboxReportAtom);


    function formarTheaterPassReport(item) {
        const totalSeatAAH = (item.total_seat && item.total_seat.length > 0)
            ? (item.total_seat.find(seat => seat.zone_name === 'aah! [A]')?.total || 0)
            : 0;
        const totalSeatOOH = (item.total_seat && item.total_seat.length > 0)
            ? (item.total_seat.find(seat => seat.zone_name === 'ooh! [O]')?.total || 0)
            : 0;
        const totalSeatWOW = (item.total_seat && item.total_seat.length > 0)
            ? (item.total_seat.find(seat => seat.zone_name === 'wow! [W]')?.total || 0)
            : 0;

        const paidAAH = (item.paid && item.paid.length > 0)
            ? (item.paid.find(seat => seat.zone_name === 'aah! [A]')?.total || 0)
            : 0;
        const paidOOH = (item.paid && item.paid.length > 0)
            ? (item.paid.find(seat => seat.zone_name === 'ooh! [O]')?.total || 0)
            : 0;
        const paidWOW = (item.paid && item.paid.length > 0)
            ? (item.paid.find(seat => seat.zone_name === 'wow! [W]')?.total || 0)
            : 0;

        const paidNoShowAAH = (item.paid_noshow && item.paid_noshow.length > 0)
            ? (item.paid_noshow.find(seat => seat.zone_name === 'aah! [A]')?.total || 0)
            : 0;
        const paidNoShowOOH = (item.paid_noshow && item.paid_noshow.length > 0)
            ? (item.paid_noshow.find(seat => seat.zone_name === 'ooh! [O]')?.total || 0)
            : 0;
        const paidNoShowWOW = (item.paid_noshow && item.paid_noshow.length > 0)
            ? (item.paid_noshow.find(seat => seat.zone_name === 'wow! [W]')?.total || 0)
            : 0;

        const complimentaryAAH = (item.complimentary && item.complimentary.length > 0)
            ? (item.complimentary.find(seat => seat.zone_name === 'aah! [A]')?.total || 0)
            : 0;
        const complimentaryOOH = (item.complimentary && item.complimentary.length > 0)
            ? (item.complimentary.find(seat => seat.zone_name === 'ooh! [O]')?.total || 0)
            : 0;
        const complimentaryWOW = (item.complimentary && item.complimentary.length > 0)
            ? (item.complimentary.find(seat => seat.zone_name === 'wow! [W]')?.total || 0)
            : 0;

        const complimentaryNoShowAAH = (item.complimentary_noshow && item.complimentary_noshow.length > 0)
            ? (item.complimentary_noshow.find(seat => seat.zone_name === 'aah! [A]')?.total || 0)
            : 0;
        const complimentaryNoShowOOH = (item.complimentary_noshow && item.complimentary_noshow.length > 0)
            ? (item.complimentary_noshow.find(seat => seat.zone_name === 'ooh! [O]')?.total || 0)
            : 0;
        const complimentaryNoShowWOW = (item.complimentary_noshow && item.complimentary_noshow.length > 0)
            ? (item.complimentary_noshow.find(seat => seat.zone_name === 'wow! [W]')?.total || 0)
            : 0;

        return {
            show_date: item.show_date,
            show_time: item.show_time,
            data: [
                {
                    key: 'total_seat',
                    title: 'TỔNG SỐ THEATER PASS PHÁT HÀNH [1]',
                    aah: totalSeatAAH,
                    ooh: totalSeatOOH,
                    wow: totalSeatWOW,
                    total: totalSeatAAH + totalSeatOOH + totalSeatWOW
                },
                {
                    key: 'total_seat_in_ticket_box',
                    title: 'SỐ THEATER PASS THỰC TẾ THU LẠI TỪ TICKET BOX [2]',
                    aah: paidAAH + complimentaryAAH,
                    ooh: paidOOH + complimentaryOOH,
                    wow: paidWOW + complimentaryWOW,
                    total: paidAAH + complimentaryAAH + paidOOH + complimentaryOOH + paidWOW + complimentaryWOW
                },
                {
                    key: 'paid',
                    title: 'KHÁCH MUA ĐẾN',
                    aah: paidAAH,
                    ooh: paidOOH,
                    wow: paidWOW,
                    total: paidAAH + paidOOH + paidWOW
                },
                {
                    key: 'paid_noshow',
                    title: 'KHÁCH MUA KHÔNG ĐẾN',
                    aah: paidNoShowAAH,
                    ooh: paidNoShowOOH,
                    wow: paidNoShowWOW,
                    total: paidNoShowAAH + paidNoShowOOH + paidNoShowWOW
                },
                {
                    key: 'complimentary',
                    title: 'KHÁCH MỜI ĐẾN',
                    aah: complimentaryAAH,
                    ooh: complimentaryOOH,
                    wow: complimentaryWOW,
                    total: complimentaryAAH + complimentaryOOH + complimentaryWOW
                },
                {
                    key: 'complimentary_noshow',
                    title: 'KHÁCH MỜI KHÔNG ĐẾN',
                    aah: complimentaryNoShowAAH,
                    ooh: complimentaryNoShowOOH,
                    wow: complimentaryNoShowWOW,
                    total: complimentaryNoShowAAH + complimentaryNoShowOOH + complimentaryNoShowWOW
                },
            ]
        }
    }

    function getListTheaterPassReport(filter) {
        const params = {
            ...filter
        }

        return instance({
            url: "booking/v1/reports/theater-pass",
            method: "GET",
            headers,
            params,
        })
            .then((res) => {
                if (res.status === 200) {
                    const data = res.data && res.data.data && res.data.data.length > 0 ? res.data.data : [];
                    const listTheaterPassReport = [];
                    for (const item of data) {
                        listTheaterPassReport.push(formarTheaterPassReport(item));
                    }
                    setListTheaterPassReport(listTheaterPassReport);
                    return res.data.data;
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    function formatTicketboxReport(data) {
        const knownPaymentMethods = [
            'Pay by Cash',
            'Pay by CreditCard VCB',
            'Pay by CreditCard AGR',
            'Pay by CreditCard BIDV',
            'Pay by Bank Transfer'
        ];

        const paidCash = (data && data.paid && data.paid.length > 0)
            ? (data.paid.find(paid => paid.payment_method === 'Pay by Cash')?.total || 0) : 0;
        const paidCCVCB = (data && data.paid && data.paid.length > 0)
            ? (data.paid.find(paid => paid.payment_method === 'Pay by CreditCard VCB')?.total || 0) : 0;
        const paidCCAGR = (data && data.paid && data.paid.length > 0)
            ? (data.paid.find(paid => paid.payment_method === 'Pay by CreditCard AGR')?.total || 0) : 0;
        const paidCCBIDV = (data && data.paid && data.paid.length > 0)
            ? (data.paid.find(paid => paid.payment_method === 'Pay by CreditCard BIDV')?.total || 0) : 0;
        const paidBankTransfer = (data && data.paid && data.paid.length > 0)
            ? (data.paid.find(paid => paid.payment_method === 'Pay by Bank Transfer')?.total || 0) : 0;
        const paidOtherMethods = (data && data.paid && data.paid.length > 0)
            ? data.paid.filter(paid => !knownPaymentMethods.includes(paid.payment_method)) : [];
        const paidOthers = paidOtherMethods.reduce((sum, payment) => sum + (payment.total || 0), 0);

        const liabilityCash = (data && data.liability && data.liability.length > 0)
            ? (data.liability.find(liability => liability.payment_method === 'Pay by Cash')?.total || 0) : 0;
        const liabilityCCVCB = (data && data.liability && data.liability.length > 0)
            ? (data.liability.find(liability => liability.payment_method === 'Pay by CreditCard VCB')?.total || 0) : 0;
        const liabilityCCAGR = (data && data.liability && data.liability.length > 0)
            ? (data.liability.find(liability => liability.payment_method === 'Pay by CreditCard AGR')?.total || 0) : 0;
        const liabilityCCBIDV = (data && data.liability && data.liability.length > 0)
            ? (data.liability.find(liability => liability.payment_method === 'Pay by CreditCard BIDV')?.total || 0) : 0;
        const liabilityBankTransfer = (data && data.liability && data.liability.length > 0)
            ? (data.liability.find(liability => liability.payment_method === 'Pay by Bank Transfer')?.total || 0) : 0;
        const liabilityOtherMethods = (data && data.liability && data.liability.length > 0)
            ? data.liability.filter(liability => !knownPaymentMethods.includes(liability.payment_method)) : [];
        const liabilityOthers = liabilityOtherMethods.reduce((sum, payment) => sum + (payment.total || 0), 0);

        const depositCash = (data && data.deposit && data.deposit.length > 0)
            ? (data.deposit.find(deposit => deposit.payment_method === 'Pay by Cash')?.total || 0) : 0;
        const depositCCVCB = (data && data.deposit && data.deposit.length > 0)
            ? (data.deposit.find(deposit => deposit.payment_method === 'Pay by CreditCard VCB')?.total || 0) : 0;
        const depositCCAGR = (data && data.deposit && data.deposit.length > 0)
            ? (data.deposit.find(deposit => deposit.payment_method === 'Pay by CreditCard AGR')?.total || 0) : 0;
        const depositCCBIDV = (data && data.deposit && data.deposit.length > 0)
            ? (data.deposit.find(deposit => deposit.payment_method === 'Pay by CreditCard BIDV')?.total || 0) : 0;
        const depositBankTransfer = (data && data.deposit && data.deposit.length > 0)
            ? (data.deposit.find(deposit => deposit.payment_method === 'Pay by Bank Transfer')?.total || 0) : 0;
        const depositOtherMethods = (data && data.deposit && data.deposit.length > 0)
            ? data.deposit.filter(deposit => !knownPaymentMethods.includes(deposit.payment_method)) : [];
        const depositOthers = depositOtherMethods.reduce((sum, payment) => sum + (payment.total || 0), 0);

        const refundCash = (data && data.refund && data.refund.length > 0)
            ? (data.refund.find(refund => refund.payment_method === 'Pay by Cash')?.total || 0) : 0;
        const refundCCVCB = (data && data.refund && data.refund.length > 0)
            ? (data.refund.find(refund => refund.payment_method === 'Pay by CreditCard VCB')?.total || 0) : 0;
        const refundCCAGR = (data && data.refund && data.refund.length > 0)
            ? (data.refund.find(refund => refund.payment_method === 'Pay by CreditCard AGR')?.total || 0) : 0;
        const refundCCBIDV = (data && data.refund && data.refund.length > 0)
            ? (data.refund.find(refund => refund.payment_method === 'Pay by CreditCard BIDV')?.total || 0) : 0;
        const refundBankTransfer = (data && data.refund && data.refund.length > 0)
            ? (data.refund.find(refund => refund.payment_method === 'Pay by Bank Transfer')?.total || 0) : 0;
        const refundOtherMethods = (data && data.refund && data.refund.length > 0)
            ? data.refund.filter(refund => !knownPaymentMethods.includes(refund.payment_method)) : [];
        const refundOthers = refundOtherMethods.reduce((sum, payment) => sum + (payment.total || 0), 0);

        return {
            list: data && data.list ? data.list : "",
            dataTable: [
                {
                    label: 'Cash đầu ngày',
                    cash: '',
                    ccVCB: '',
                    ccAGR: '',
                    ccBIDV: '',
                    bankTransfer: '',
                    others: ''
                },
                {
                    label: 'THU BOOKING',
                    cash: paidCash,
                    ccVCB: paidCCVCB,
                    ccAGR: paidCCAGR,
                    ccBIDV: paidCCBIDV,
                    bankTransfer: paidBankTransfer,
                    others: paidOthers,
                },
                {
                    label: 'THU CÔNG NỢ',
                    cash: liabilityCash,
                    ccVCB: liabilityCCVCB,
                    ccAGR: liabilityCCAGR,
                    ccBIDV: liabilityCCBIDV,
                    bankTransfer: liabilityBankTransfer,
                    others: liabilityOthers,
                },
                {
                    label: 'THU DEPOSIT',
                    cash: depositCash,
                    ccVCB: depositCCVCB,
                    ccAGR: depositCCAGR,
                    ccBIDV: depositCCBIDV,
                    bankTransfer: depositBankTransfer,
                    others: depositOthers,
                },
                {
                    label: 'TỔNG THU/ HÌNH THỨC THANH TOÁN',
                    cash: paidCash + liabilityCash + depositCash,
                    ccVCB: paidCCVCB + liabilityCCVCB + depositCCVCB,
                    ccAGR: paidCCAGR + liabilityCCAGR + depositCCAGR,
                    ccBIDV: paidCCBIDV + liabilityCCBIDV + depositCCBIDV,
                    bankTransfer: paidBankTransfer + liabilityBankTransfer + depositBankTransfer,
                    others: paidOthers + liabilityOthers + depositOthers
                },
                {
                    label: 'CHI [REFUND]',
                    cash: refundCash,
                    ccVCB: refundCCVCB,
                    ccAGR: refundCCAGR,
                    ccBIDV: refundCCBIDV,
                    bankTransfer: refundBankTransfer,
                    others: refundOthers
                },
                {
                    label: 'TỔNG CHI',
                    total: refundCash + refundCCVCB + refundCCVCB + refundCCAGR + refundCCBIDV + refundBankTransfer + refundOthers,
                    isMerged: true
                },
                {
                    label: 'TỔNG THU/NGÀY',
                    total: paidCash + liabilityCash + depositCash + paidCCVCB + liabilityCCVCB + depositCCVCB + paidCCAGR + liabilityCCAGR + depositCCAGR + paidCCBIDV + liabilityCCBIDV + depositCCBIDV + paidBankTransfer + liabilityBankTransfer + depositBankTransfer + paidOthers + liabilityOthers + depositOthers,
                    isMerged: true
                },
                {
                    label: 'NỘP CASHIER',
                    total: 0,
                    isMerged: true
                }
            ]
        }
    }

    function getTicketboxReport(filter) {

        return instance({
            url: `booking/v1/reports/daily-ticketbox?${filter}`,
            method: "GET",
            headers,
        })
            .then((res) => {
                if (res.status === 200) {
                    const data = res.data && res.data.data;
                    setListTicketboxReport(formatTicketboxReport(data));
                    return res.data.data;
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    function getShowReport(filter) {
        const params = {
            sort_type: "desc",
            sort_field: "created_at",
            ...filter,
        };
        return instance({
            url: `booking/v1/reports/shows`,
            method: "GET",
            params,
            headers,
        });
    }

    function exportShowReport(filter) {
        const params = {
            sort_type: "desc",
            sort_field: "created_at",
            ...filter,
        };
        headers.responseType = "blob";
        return instance({
            url: `booking/v1/reports/shows/export`,
            method: "GET",
            params,
            responseType: "blob",
            headers,
        });
    }

    return {
        getListTheaterPassReport,
        getTicketboxReport,
        getShowReport,
        exportShowReport
    }
}

export { useReportActions };