import { atom } from "recoil";

const newsAtom = atom({
  key: "newsAtom",
  default: [],
});
const newGroupsAtom = atom({
  key: "newGroupsAtom",
  default: [],
});
const partnerAtom = atom({
  key: "partnerAtom",
  default: [],
});
const venueWebAtom = atom({
  key: "venueWebAtom",
  default: [],
});
const locationWebAtom = atom({
  key: "locationWebAtom",
  default: [],
});
const bannerAtom = atom({
  key: "bannerAtom",
  default: [],
});
const showWebAtom = atom({
  key: "showWebAtom",
  default: [],
});
const offersAtom = atom({
  key: "offersAtom",
  default: [],
});
const offersGroupAtom = atom({
  key: "offersGroupAtom",
  default: [],
});
const menuAtom = atom({
  key: "menuAtom",
  default: [],
});
const recruitmentAtom = atom({
  key: "recruitmentAtom",
  default: [],
});
const faqAtom = atom({
  key: "faqAtom",
  default: [],
});
const faqGroupAtom = atom({
  key: "faqGroupAtom",
  default: [],
});
const showReviewAtom = atom({
  key: "showReviewAtom",
  default: [],
});
const showKeyFeatureAtom = atom({
  key: "showKeyFeatureAtom",
  default: [],
});
export {
  locationWebAtom,
  bannerAtom,
  newsAtom,
  newGroupsAtom,
  partnerAtom,
  venueWebAtom,
  showWebAtom,
  offersGroupAtom,
  offersAtom,
  menuAtom,
  recruitmentAtom,
  faqAtom,
  faqGroupAtom,
  showReviewAtom,
  showKeyFeatureAtom
};
