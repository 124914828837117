import {
  Flex,
  Text,
  useToast,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormLabel,
  Grid,
  Stack,
  useColorModeValue,
  Box,
  Select as SelectSearch,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useUserActions } from "hooks/useUserActions";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { unitsUserAtom } from "state/users";
import {
  columnsDataUnitUser,
  columnsDataRatePlanUnitsV2,
} from "variables/columnsData";
import { totalRow } from "state/roles-permissions";
import { useForm, Controller } from "react-hook-form";
import { useBookingActions } from "hooks/useBookingActions";
import {
  listChannelAtom,
  listUnitAtom,
  listUnitForAddingAtom,
} from "state/show";
import { Select } from "chakra-react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { AddIcon } from "@chakra-ui/icons";
import { BsSearch } from "react-icons/bs";
import { RxReset } from "react-icons/rx";
import { checkForbidden } from "helpers/checkForbidden";
import SearchTable2 from "components/Tables/SearchTable2";

function DataTableUnitUser({ userID, userDetail }) {
  const textColor = useColorModeValue("gray.700", "white");
  const userActions = useUserActions();
  const bookingActions = useBookingActions();
  const totalRows = useRecoilValue(totalRow);
  const listChannel = useRecoilValue(listChannelAtom);
  const [listUnit, setListUnit] = useRecoilState(listUnitAtom);
  const listUnitForAdding = useRecoilValue(listUnitForAddingAtom);
  const unitsUser = useRecoilValue(unitsUserAtom);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [listCheckedChoose, setListCheckedChoose] = useState([]);
  const [listCheckedChooseUnit, setListCheckedChooseUnit] = useState([]);
  const [newUnitsUser, setNewUnitsUser] = useState([]);
  const [clearAllCheckBox, setClearAllCheckBox] = useState();
  const [isActiveAll, setIsActiveAll] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const {
    isOpen: isOpenConfirm,
    onOpen: onOpenConfirm,
    onClose: onCloseConfirm,
  } = useDisclosure();

  const chakraStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      p: 0,
      w: "20px",
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: "250px",
      zIndex: 10,
    }),
  };
  const validationSchema = Yup.object().shape({
    channel: Yup.object().required("This field is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    handleSubmit,
    setError,
    formState,
    reset,
    control,
    watch,
    setValue,
  } = useForm(formOptions);
  const {
    handleSubmit: handleSubmitSearch,
    control: controlSearch,
    watch: watchSearch,
    reset: resetSearch,
    setValue: setValueSearch,
    register: registerSearch,
  } = useForm();
  const { errors, isSubmitting } = formState;
  const channel = watch("channel");
  const channelSearch = watchSearch("channelSearch");
  const unitSearch = watchSearch("unitSearch");
  const statusSearch = watchSearch("statusSearch");
  const toast = useToast();
  useEffect(() => {
    bookingActions.getListChannel(
      {
        status: 1,
      },
      0,
      ""
    );
  }, []);
  useEffect(() => {
    if (unitsUser) {
      const newUnitUsers = unitsUser.map((i) => ({
        ...i,
        typeSwitch: "unitUser",
        filters: {
          channel_id: channelSearch?.id,
          unit_ids: unitSearch?.map((i) => i.id).join(",") ?? undefined,
          status: statusSearch,
        },
        userID: userID,
      }));
      setNewUnitsUser(newUnitUsers);
    }
  }, [unitsUser]);
  useEffect(() => {
    if (channel) {
      setIsFetching(true);
      bookingActions.getListUnitsNotIncludingUnitOfUser(
        { channel_id: channel.id },
        userID,
        0,
        10
      );
      setIsFetching(false);
    }
  }, [channel]);
  useEffect(() => {
    if (channelSearch) {
      bookingActions.getListUnits({ channel_id: channelSearch.id }, 0, "");
    }
  }, [channelSearch]);

  function onSubmitSearch() {
    getAllUnitsOfUser("search");
  }
  const recallApi = (fetchDataOptions) => {
    getAllUnitsOfUser("", fetchDataOptions);
  };
  const recallApi2 = (fetchDataOptions) => {
    bookingActions.getListUnitsNotIncludingUnitOfUser(
      { channel_id: channel.id },
      userID,
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
  };
  async function getAllUnitsOfUser(type, fetchDataOptions) {
    setIsFetching(true);
    const unit_ids = unitSearch?.map((i) => i.id).join(",") ?? "";
    const filter = {
      channel_id: channelSearch?.id || "",
      unit_ids,
      status: statusSearch ?? 1,
    };
    await userActions.getUnitsOfUser(
      type === "search"
        ? filter
        : {
            status: statusSearch ?? 1,
          },
      userID,
      type === "search" || type === "recall" ? 0 : fetchDataOptions.pageIndex,
      type === "search" || type === "recall"
        ? localStorage && localStorage.getItem("currentPageSizeStore")
          ? localStorage.getItem("currentPageSizeStore")
          : 10
        : fetchDataOptions.pageSize
    );
    setIsFetching(false);
  }
  async function onSubmit() {
    if (isSubmitting) {
      return;
    }
    const unit_ids = listCheckedChoose.map((i) => parseInt(i));
    try {
      await userActions.createUserUnit({ unit_ids }, userID);
      onClose();
      reset();
      setValue("channel", null);
      setValue("unit", null);
      toast({
        description: `Create Unit user successful`,
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      getAllUnitsOfUser("", {
        pageIndex: 0,
        pageSize: 10,
      });
    } catch (err) {
      setError("apiError", { message: err.response.data.meta.message });
    }
  }

  const UnitUser = [
    { Id: "0", Name: "All" },
    { Id: "1", Name: "Active" },
    { Id: "2", Name: "InActive" },
  ];

  const getValueChecked = (val) => {
    if (val) {
      const checkedIDs = Object.keys(val);
      setListCheckedChoose(checkedIDs);
    }
  };
  const getValueChecked2 = (val) => {
    if (val) {
      const checkedIDs = Object.keys(val);
      setListCheckedChooseUnit(checkedIDs);
    }
  };

  const confirmSwitchAll = () => {
    const unit_ids = listCheckedChooseUnit.map((i) => parseInt(i));
    userActions
      .updateUserUnitStatus(isActiveAll ? 1 : 2, userID, {
        unit_id: unit_ids,
        userID: userID,
      })
      .then((res) => {
        if (res.status === 200) {
          onCloseConfirm();
          toast({
            description: `Update all status Unit of user successful`,
            status: "success",
            duration: 4000,
            isClosable: true,
            position: "top-right",
          });
          setClearAllCheckBox(Math.random());
          getAllUnitsOfUser("search");
        }
      });
  };
  const createAll = async () => {
    try {
      await userActions.createUserUnit({ channel_id: channel.id }, userID);
      onClose();
      reset();
      setValue("channel", null);
      setValue("unit", null);
      toast({
        description: `Create Unit users successful`,
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      getAllUnitsOfUser("", {
        pageIndex: 0,
        pageSize: 10,
      });
    } catch (err) {
      setError("apiError", { message: err.response.data.meta.message });
    }
  };

  return (
    <Flex direction="column">
      <Card px="0px">
        <CardHeader px="22px">
          <Flex direction="column" display="block">
            <Text
              float="left"
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              mb="6px"
            >
              Units of User - {userDetail?.fullname}
            </Text>
            <Stack float="right" direction="row" spacing={4}>
              {checkForbidden(38) && (
                <Button
                  size="sm"
                  onClick={() => {
                    onOpen();
                    setError("apiError", { message: "" });
                  }}
                  leftIcon={<AddIcon />}
                  colorScheme="green"
                  variant="outline"
                >
                  Assign Unit to User
                </Button>
              )}
            </Stack>
            <Modal
              closeOnOverlayClick={false}
              isOpen={isOpen}
              onClose={() => {
                onClose();
                reset();
              }}
              size="4xl"
            >
              <ModalOverlay />
              <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                  <ModalHeader>Assign Unit to User</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <Grid
                      gap={{ lg: "4px", sm: "8px" }}
                      templateColumns={{ lg: "1fr", sm: "1fr" }}
                    >
                      <Flex direction="column" maxW="200">
                        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                          Channel
                        </FormLabel>
                        <Controller
                          name="channel"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              focusBorderColor="blue.500"
                              chakraStyles={chakraStyles}
                              options={listChannel}
                              placeholder="Select Channel"
                              getOptionLabel={(i) => i.name}
                              getOptionValue={(option) => option.id}
                              size="sm"
                              closeMenuOnSelect={true}
                            />
                          )}
                        />
                        <Text color="red" fontSize={14} mb="10px" ml="5px">
                          {errors.channel?.message}
                        </Text>
                      </Flex>
                      {channel && (
                        <Flex direction="column">
                          <Text mb={1}>
                            Total selected: {listCheckedChoose.length || 0}
                          </Text>
                          <SearchTable2
                            tableData={listUnitForAdding}
                            columnsData={columnsDataRatePlanUnitsV2}
                            totalRow={totalRows.unitListForAdding}
                            loadData={(fetchDataOptions) =>
                              recallApi2(fetchDataOptions)
                            }
                            updateCheckbox={(val) => getValueChecked(val)}
                            enableRowActions={false}
                            enableRowSelection={true}
                            isFetching={isFetching}
                          />
                        </Flex>
                      )}
                    </Grid>
                    <Text color="red" ml="5px">
                      {errors.apiError && errors.apiError?.message}
                    </Text>
                  </ModalBody>
                  <ModalFooter pt="0">
                    {channel && listUnitForAdding.length > 0 && (
                      <Button
                        colorScheme="blue"
                        mr={3}
                        onClick={() => createAll()}
                      >
                        Assign All
                      </Button>
                    )}
                    <Button
                      disabled={listCheckedChoose.length < 1}
                      colorScheme="blue"
                      mr={3}
                      type="submit"
                    >
                      Assign
                    </Button>

                    <Button
                      onClick={() => {
                        onClose();
                        reset();
                      }}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </form>
            </Modal>
          </Flex>
        </CardHeader>
        <CardBody>
          <Card>
            <form
              id="unit-user-search-form"
              onSubmit={handleSubmitSearch(onSubmitSearch)}
            >
              <Flex
                direction="row"
                alignItems="center"
                mb={3}
                gap={4}
                flexWrap={"wrap"}
              >
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                    Channel
                  </FormLabel>
                  <Controller
                    name="channelSearch"
                    control={controlSearch}
                    render={({ field }) => (
                      <Select
                        {...field}
                        focusBorderColor="blue.500"
                        chakraStyles={chakraStyles}
                        options={listChannel}
                        placeholder="Select Channel"
                        getOptionLabel={(i) => i.name}
                        getOptionValue={(option) => option.id}
                        size="sm"
                        closeMenuOnSelect={true}
                      />
                    )}
                  />
                </Box>
                {channelSearch && (
                  <Box direction="row" alignItems="center" display="block">
                    <FormLabel ms="4px" fontSize="xs" fontWeight="semibold">
                      Unit
                    </FormLabel>
                    <Controller
                      name="unitSearch"
                      control={controlSearch}
                      render={({ field }) => (
                        <Select
                          {...field}
                          focusBorderColor="blue.500"
                          chakraStyles={chakraStyles}
                          options={listUnit}
                          placeholder="Select Unit"
                          getOptionLabel={(i) => i.name}
                          getOptionValue={(option) => option.id}
                          size="sm"
                          closeMenuOnSelect={false}
                          isMulti
                        />
                      )}
                    />
                  </Box>
                )}
                <Box direction="row" alignItems="center" width="150px">
                  <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                    Status
                  </FormLabel>
                  <SelectSearch
                    ms="4px"
                    type="text"
                    defaultValue="1"
                    size="sm"
                    name="statusSearch"
                    {...registerSearch("statusSearch")}
                  >
                    {UnitUser.map((item, index) => (
                      <option key={index} value={item.Id}>
                        {item.Name}
                      </option>
                    ))}
                  </SelectSearch>
                </Box>
              </Flex>
              <Flex justify="center" align="center" gap="8px" mt={3}>
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<RxReset />}
                    variant="primary"
                    w="88px"
                    h="33px"
                    onClick={() => {
                      resetSearch();
                      setValueSearch("channelSearch", null);
                      setValueSearch("unitSearch", null);
                      setListUnit([]);
                    }}
                  >
                    Reset
                  </Button>
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<BsSearch />}
                    variant="primary"
                    w="88px"
                    h="33px"
                    type="submit"
                  >
                    Search
                  </Button>
                </Box>
              </Flex>
            </form>
          </Card>
          {listCheckedChooseUnit.length > 1 && (
            <Flex gap={2} mb={4}>
              <Button
                onClick={() => {
                  onOpenConfirm();
                  setIsActiveAll(true);
                }}
                variant="primary"
                mt="24px"
                ml="24px"
                h="35px"
              >
                Activate selected Units
              </Button>
              <Button
                onClick={() => {
                  onOpenConfirm();
                  setIsActiveAll(false);
                }}
                variant="primary"
                mt="24px"
                ml="24px"
                h="35px"
              >
                Deactivate selected Units
              </Button>
            </Flex>
          )}
          <SearchTable2
            tableData={newUnitsUser}
            columnsData={columnsDataUnitUser}
            totalRow={totalRows.unitsUser}
            loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
            updateCheckbox={(val) => getValueChecked2(val)}
            listPermissions={[39]}
            enableRowActions={false}
            enableRowSelection={true}
            isFetching={isFetching}
          />
        </CardBody>
        <Modal isOpen={isOpenConfirm} onClose={() => cancel()}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirm Action</ModalHeader>
            <ModalBody>Are you sure you want to perform this action?</ModalBody>
            <ModalFooter>
              <Button variant="ghost" onClick={() => onCloseConfirm()}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                ml={3}
                onClick={() => confirmSwitchAll()}
              >
                Confirm
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Card>
    </Flex>
  );
}

export default DataTableUnitUser;
