import { atom } from "recoil";

const vouchersAtom = atom({
  key: "vouchers",
  default: [],
});
const voucherCodesAtom = atom({
  key: "voucherCodes",
  default: [],
});
const vouchersTemplateAtom = atom({
  key: "voucherTemplates",
  default: [],
});

export { vouchersAtom, voucherCodesAtom, vouchersTemplateAtom };
