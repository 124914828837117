import instance from "helpers/instance";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authAtom } from "state/auth";
import {
  newGroupsAtom,
  partnerAtom,
  newsAtom,
  bannerAtom,
  venueWebAtom,
  locationWebAtom,
  showWebAtom,
  offersAtom,
  offersGroupAtom,
  menuAtom,
  recruitmentAtom,
  faqAtom,
  faqGroupAtom,
  showReviewAtom,
  showKeyFeatureAtom
} from "state/cms";
import { totalRow } from "state/roles-permissions";
import _ from "lodash";
const useCMSActions = () => {
  const auth = useRecoilValue(authAtom);
  const setTotalRows = useSetRecoilState(totalRow);
  const setNewsAtom = useSetRecoilState(newsAtom);
  const setNewsGroupAtom = useSetRecoilState(newGroupsAtom);
  const setPartnerAtom = useSetRecoilState(partnerAtom);
  const setVenueWebAtom = useSetRecoilState(venueWebAtom);
  const setLocationWebAtom = useSetRecoilState(locationWebAtom);
  const setBannerAtom = useSetRecoilState(bannerAtom);
  const setShowWebAtom = useSetRecoilState(showWebAtom);
  const setOffersAtom = useSetRecoilState(offersAtom);
  const setOffersGroupAtom = useSetRecoilState(offersGroupAtom);
  const setRecruitmentAtom = useSetRecoilState(recruitmentAtom);
  const setMenuAtom = useSetRecoilState(menuAtom);
  const setFaqAtom = useSetRecoilState(faqAtom);
  const setFaqGroupAtom = useSetRecoilState(faqGroupAtom);
  const setShowReviewAtom = useSetRecoilState(showReviewAtom);
  const setShowKeyFeatureAtom = useSetRecoilState(showKeyFeatureAtom);

  
  const headers = {
    Authorization: `Bearer ${auth?.access_token}`,
  };
  function createBanner(data) {
    return instance({
      url: "cms/v1/banners",
      method: "POST",
      data,
      headers,
    });
  }
  function addBannerImage(data) {
    return instance({
      url: `cms/v1/banner-images`,
      method: "POST",
      data,
      headers,
    });
  }
  function updateBannerImage(data, idM) {
    return instance({
      url: `/cms/v1/banner-images/${idM}`,
      method: "PUT",
      data,
      headers,
    });
  }
  function updateBanner(data, id) {
    return instance({
      url: `cms/v1/banners/${id}`,
      method: "PUT",
      data,
      headers,
    });
  }
  function deleteImage(id) {
    return instance({
      url: `cms/v1/banners/${id}`,
      method: "DELETE",
      headers,
    });
  }
  function getBannerDetail(id) {
    return instance({
      url: `cms/v1/banners/${id}`,
      method: "GET",
      headers,
    });
  }
  function getBannerImageDetail(id) {
    return instance({
      url: `cms/v1/banner-images/${id}`,
      method: "GET",
      headers,
    });
  }
  function activeBanner(data, id) {
    return instance({
      url: `cms/v1/banners/${id}/active`,
      method: "PUT",
      headers,
    });
  }
  function inactiveBanner(data, id) {
    return instance({
      url: `cms/v1/banners/${id}/inactive`,
      method: "PUT",
      headers,
    });
  }
  function getListBanner(filter = {}, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: `cms/v1/banners`,
      method: "GET",
      params,
      headers,
    }).then((res) => {
      if (res.status === 200) {
        setBannerAtom(res.data.data);
        setTotalRows((preState) => ({
          ...preState,
          banner: res.data.meta.total,
        }));
      }
    });
  }

  function createNews(data) {
    return instance({
      url: "cms/v1/news",
      method: "POST",
      data,
      headers,
    });
  }
  function updateNews(data, id) {
    return instance({
      url: `cms/v1/news/${id}`,
      method: "PUT",
      data,
      headers,
    });
  }
  function activeNews(data, id) {
    return instance({
      url: `cms/v1/news/${id}/publish`,
      method: "PUT",
      headers,
    });
  }
  function inactiveNews(data, id) {
    return instance({
      url: `cms/v1/news/${id}/draft`,
      method: "PUT",
      headers,
    });
  }
  function getNewsDetail(id) {
    return instance({
      url: `cms/v1/news/${id}`,
      method: "GET",
      headers,
    });
  }

  function getListNews(filter = {}, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: `cms/v1/news`,
      method: "GET",
      params,
      headers,
    }).then((res) => {
      if (res.status === 200) {
        setNewsAtom(res.data.data);
        setTotalRows((preState) => ({
          ...preState,
          news: res.data.meta.total,
        }));
      }
    });
  }

  function createNewsGroup(data) {
    return instance({
      url: "cms/v1/news-groups",
      method: "POST",
      data,
      headers,
    });
  }
  function updateNewsGroup(data, id) {
    return instance({
      url: `cms/v1/news-groups/${id}`,
      method: "PUT",
      data,
      headers,
    });
  }
  function activeNewGroup(data, id) {
    return instance({
      url: `cms/v1/news-groups/${id}/active`,
      method: "PUT",
      headers,
    });
  }
  function inactiveNewGroup(data, id) {
    return instance({
      url: `cms/v1/news-groups/${id}/inactive`,
      method: "PUT",
      headers,
    });
  }
  function getNewsDetailGroup(id) {
    return instance({
      url: `cms/v1/news-groups/${id}`,
      method: "GET",
      headers,
    });
  }

  function getListNewsGroup(filter = {}, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: `cms/v1/news-groups`,
      method: "GET",
      params,
      headers,
    }).then((res) => {
      if (res.status === 200) {
        setNewsGroupAtom(res.data.data);
        setTotalRows((preState) => ({
          ...preState,
          newsGroup: res.data.meta.total,
        }));
      }
    });
  }

  function createMenu(data) {
    return instance({
      url: "cms/v1/menus",
      method: "POST",
      data,
      headers,
    });
  }
  function updateMenu(data, id) {
    return instance({
      url: `cms/v1/menus/${id}`,
      method: "PUT",
      data,
      headers,
    });
  }
  function activeMenu(data, id) {
    return instance({
      url: `cms/v1/menus/${id}/active`,
      method: "PUT",
      headers,
    });
  }
  function inactiveMenu(data, id) {
    return instance({
      url: `cms/v1/menus/${id}/inactive`,
      method: "PUT",
      headers,
    });
  }
  function getDetailMenu(id) {
    return instance({
      url: `cms/v1/menus/${id}`,
      method: "GET",
      headers,
    });
  }
  function getAllChildren(item) {
    if (!item.children) {
      return [];
    }
    return _.flatMap(item.children, (child) => [
      child,
      ...getAllChildren(child),
    ]);
  }
  function getListMenu(filter = {}, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: `cms/v1/menus`,
      method: "GET",
      params,
      headers,
    }).then((res) => {
      if (res.status === 200) {
        setMenuAtom(
          _.flatMap(res.data.data, (item) => [item, ...getAllChildren(item)])
        );
        setTotalRows((preState) => ({
          ...preState,
          menu: res.data.meta.total,
        }));
      }
    });
  }

  function createShow(data) {
    return instance({
      url: "cms/v1/shows",
      method: "POST",
      data,
      headers,
    });
  }
  function updateShow(data, id) {
    return instance({
      url: `cms/v1/shows/${id}`,
      method: "PUT",
      data,
      headers,
    });
  }
  function activeShow(data, id) {
    return instance({
      url: `cms/v1/shows/${id}/active`,
      method: "PUT",
      headers,
    });
  }
  function inactiveShow(data, id) {
    return instance({
      url: `cms/v1/shows/${id}/inactive`,
      method: "PUT",
      headers,
    });
  }
  function getDetailShow(id) {
    return instance({
      url: `cms/v1/shows/${id}`,
      method: "GET",
      headers,
    });
  }

  function getListShow(filter = {}, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: `cms/v1/shows`,
      method: "GET",
      params,
      headers,
    }).then((res) => {
      if (res.status === 200) {
        setShowWebAtom(res.data.data);
        setTotalRows((preState) => ({
          ...preState,
          showWeb: res.data.meta.total,
        }));
      }
    });
  }

  function createPartners(data) {
    return instance({
      url: "cms/v1/partners",
      method: "POST",
      data,
      headers,
    });
  }
  function updatePartners(data, id) {
    return instance({
      url: `cms/v1/partners/${id}`,
      method: "PUT",
      data,
      headers,
    });
  }
  function activePartners(data, id) {
    return instance({
      url: `cms/v1/partners/${id}/active`,
      method: "PUT",
      headers,
    });
  }
  function inactivePartners(data, id) {
    return instance({
      url: `cms/v1/partners/${id}/inactive`,
      method: "PUT",
      headers,
    });
  }
  function getDetailPartners(id) {
    return instance({
      url: `cms/v1/partners/${id}`,
      method: "GET",
      headers,
    });
  }

  function getListPartners(filter = {}, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: `cms/v1/partners`,
      method: "GET",
      params,
      headers,
    }).then((res) => {
      if (res.status === 200) {
        setPartnerAtom(res.data.data);
        setTotalRows((preState) => ({
          ...preState,
          partner: res.data.meta.total,
        }));
      }
    });
  }

  function createVenues(data) {
    return instance({
      url: "cms/v1/venues",
      method: "POST",
      data,
      headers,
    });
  }
  function updateVenues(data, id) {
    return instance({
      url: `cms/v1/venues/${id}`,
      method: "PUT",
      data,
      headers,
    });
  }
  function activeVenues(data, id) {
    return instance({
      url: `cms/v1/venues/${id}/active`,
      method: "PUT",
      headers,
    });
  }
  function inactiveVenues(data, id) {
    return instance({
      url: `cms/v1/venues/${id}/inactive`,
      method: "PUT",
      headers,
    });
  }
  function getDetailVenues(id) {
    return instance({
      url: `cms/v1/venues/${id}`,
      method: "GET",
      headers,
    });
  }

  function getListVenues(filter = {}, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: `cms/v1/venues`,
      method: "GET",
      params,
      headers,
    }).then((res) => {
      if (res.status === 200) {
        setVenueWebAtom(res.data.data);
        setTotalRows((preState) => ({
          ...preState,
          venueWeb: res.data.meta.total,
        }));
      }
    });
  }

  function createOffers(data) {
    return instance({
      url: "cms/v1/offers",
      method: "POST",
      data,
      headers,
    });
  }
  function updateOffers(data, id) {
    return instance({
      url: `cms/v1/offers/${id}`,
      method: "PUT",
      data,
      headers,
    });
  }
  function activeOffers(data, id) {
    return instance({
      url: `cms/v1/offers/${id}/active`,
      method: "PUT",
      headers,
    });
  }
  function inactiveOffers(data, id) {
    return instance({
      url: `cms/v1/offers/${id}/inactive`,
      method: "PUT",
      headers,
    });
  }
  function getDetailOffers(id) {
    return instance({
      url: `cms/v1/offers/${id}`,
      method: "GET",
      headers,
    });
  }

  function getListOffers(filter = {}, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: `cms/v1/offers`,
      method: "GET",
      params,
      headers,
    }).then((res) => {
      if (res.status === 200) {
        setOffersAtom(res.data.data);
        setTotalRows((preState) => ({
          ...preState,
          offers: res.data.meta.total,
        }));
      }
    });
  }

  function createLocations(data) {
    return instance({
      url: "cms/v1/locations",
      method: "POST",
      data,
      headers,
    });
  }
  function updateLocations(data, id) {
    return instance({
      url: `cms/v1/locations/${id}`,
      method: "PUT",
      data,
      headers,
    });
  }
  function activeLocations(data, id) {
    return instance({
      url: `cms/v1/locations/${id}/active`,
      method: "PUT",
      headers,
    });
  }
  function inactiveLocations(data, id) {
    return instance({
      url: `cms/v1/locations/${id}/inactive`,
      method: "PUT",
      headers,
    });
  }
  function getDetailLocations(id) {
    return instance({
      url: `cms/v1/locations/${id}`,
      method: "GET",
      headers,
    });
  }

  function getListLocations(filter = {}, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: `cms/v1/locations`,
      method: "GET",
      params,
      headers,
    }).then((res) => {
      if (res.status === 200) {
        setLocationWebAtom(res.data.data);
        setTotalRows((preState) => ({
          ...preState,
          loationWeb: res.data.meta.total,
        }));
      }
    });
  }

  function createOfferGroups(data) {
    return instance({
      url: "cms/v1/offer-groups",
      method: "POST",
      data,
      headers,
    });
  }
  function updateOfferGroups(data, id) {
    return instance({
      url: `cms/v1/offer-groups/${id}`,
      method: "PUT",
      data,
      headers,
    });
  }
  function activeOfferGroups(data, id) {
    return instance({
      url: `cms/v1/offer-groups/${id}/active`,
      method: "PUT",
      headers,
    });
  }
  function inactiveOfferGroups(data, id) {
    return instance({
      url: `cms/v1/offer-groups/${id}/inactive`,
      method: "PUT",
      headers,
    });
  }
  function getDetailOfferGroups(id) {
    return instance({
      url: `cms/v1/offer-groups/${id}`,
      method: "GET",
      headers,
    });
  }

  function getListOfferGroups(filter = {}, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: `cms/v1/offer-groups`,
      method: "GET",
      params,
      headers,
    }).then((res) => {
      if (res.status === 200) {
        setOffersGroupAtom(res.data.data);
        setTotalRows((preState) => ({
          ...preState,
          offersGroup: res.data.meta.total,
        }));
      }
    });
  }

  function createRecruitment(data) {
    return instance({
      url: "cms/v1/recruitments",
      method: "POST",
      data,
      headers,
    });
  }
  function updateRecruitment(data, id) {
    return instance({
      url: `cms/v1/recruitments/${id}`,
      method: "PUT",
      data,
      headers,
    });
  }
  function activeRecruitment(data, id) {
    return instance({
      url: `cms/v1/recruitments/${id}/active`,
      method: "PUT",
      headers,
    });
  }
  function inactiveRecruitment(data, id) {
    return instance({
      url: `cms/v1/recruitments/${id}/inactive`,
      method: "PUT",
      headers,
    });
  }
  function getDetailRecruitment(id) {
    return instance({
      url: `cms/v1/recruitments/${id}`,
      method: "GET",
      headers,
    });
  }

  function getListRecruitment(filter = {}, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: `cms/v1/recruitments`,
      method: "GET",
      params,
      headers,
    }).then((res) => {
      if (res.status === 200) {
        setRecruitmentAtom(res.data.data);
        setTotalRows((preState) => ({
          ...preState,
          recruitments: res.data.meta.total,
        }));
      }
    });
  }

  function createFaq(data) {
    return instance({
      url: "cms/v1/faqs",
      method: "POST",
      data,
      headers,
    });
  }
  function updateFaq(data, id) {
    return instance({
      url: `cms/v1/faqs/${id}`,
      method: "PUT",
      data,
      headers,
    });
  }
  function activeFaq(data, id) {
    return instance({
      url: `cms/v1/faqs/${id}/active`,
      method: "PUT",
      headers,
    });
  }
  function inactiveFaq(data, id) {
    return instance({
      url: `cms/v1/faqs/${id}/inactive`,
      method: "PUT",
      headers,
    });
  }
  function getDetailFaq(id) {
    return instance({
      url: `cms/v1/faqs/${id}`,
      method: "GET",
      headers,
    });
  }

  function getListFaq(filter = {}, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: `cms/v1/faqs`,
      method: "GET",
      params,
      headers,
    }).then((res) => {
      if (res.status === 200) {
        setFaqAtom(res.data.data);
        setTotalRows((preState) => ({
          ...preState,
          faqs: res.data.meta.total,
        }));
      }
    });
  }

  function createFaqGroup(data) {
    return instance({
      url: "cms/v1/faq-groups",
      method: "POST",
      data,
      headers,
    });
  }
  function updateFaqGroup(data, id) {
    return instance({
      url: `cms/v1/faq-groups/${id}`,
      method: "PUT",
      data,
      headers,
    });
  }
  function activeFaqGroup(data, id) {
    return instance({
      url: `cms/v1/faq-groups/${id}/active`,
      method: "PUT",
      headers,
    });
  }
  function inactiveFaqGroup(data, id) {
    return instance({
      url: `cms/v1/faq-groups/${id}/inactive`,
      method: "PUT",
      headers,
    });
  }
  function getDetailFaqGroup(id) {
    return instance({
      url: `cms/v1/faq-groups/${id}`,
      method: "GET",
      headers,
    });
  }

  function getListFaqGroup(filter = {}, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: `cms/v1/faq-groups`,
      method: "GET",
      params,
      headers,
    }).then((res) => {
      if (res.status === 200) {
        setFaqGroupAtom(res.data.data);
        setTotalRows((preState) => ({
          ...preState,
          faqsGroups: res.data.meta.total,
        }));
      }
    });
  }

  function createShowReview(data) {
    return instance({
      url: "cms/v1/show-reviews",
      method: "POST",
      data,
      headers,
    });
  }
  function updateShowReview(data, id) {
    return instance({
      url: `cms/v1/show-reviews/${id}`,
      method: "PUT",
      data,
      headers,
    });
  }
  function activeShowReview(data, id) {
    return instance({
      url: `cms/v1/show-reviews/${id}/active`,
      method: "PUT",
      headers,
    });
  }
  function inactiveShowReview(data, id) {
    return instance({
      url: `cms/v1/show-reviews/${id}/inactive`,
      method: "PUT",
      headers,
    });
  }
  function getDetailShowReview(id) {
    return instance({
      url: `cms/v1/show-reviews/${id}`,
      method: "GET",
      headers,
    });
  }

  function getListShowReview(filter = {}, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: `cms/v1/show-reviews`,
      method: "GET",
      params,
      headers,
    }).then((res) => {
      if (res.status === 200) {
        setShowReviewAtom(res.data.data);
        setTotalRows((preState) => ({
          ...preState,
          showReview: res.data.meta.total,
        }));
      }
    });
  }

  function createShowKeyReview(data) {
    return instance({
      url: "cms/v1/show-key-features",
      method: "POST",
      data,
      headers,
    });
  }
  function updateShowKeyReview(data, id) {
    return instance({
      url: `cms/v1/show-key-features/${id}`,
      method: "PUT",
      data,
      headers,
    });
  }
  function activeShowKeyReview(data, id) {
    return instance({
      url: `cms/v1/show-key-features/${id}/active`,
      method: "PUT",
      headers,
    });
  }
  function inactiveShowKeyReview(data, id) {
    return instance({
      url: `cms/v1/show-key-features/${id}/inactive`,
      method: "PUT",
      headers,
    });
  }
  function getDetailShowKeyReview(id) {
    return instance({
      url: `cms/v1/show-key-features/${id}`,
      method: "GET",
      headers,
    });
  }

  function getListShowKeyFeature(filter = {}, page = 0, pageSize = 10) {
    let params = {
      ...filter,
    };
    if (page >= 0 && pageSize > 0) {
      const offset = page * pageSize;
      params = {
        offset,
        limit: pageSize,
        ...filter,
      };
    }
    return instance({
      url: `cms/v1/show-key-features`,
      method: "GET",
      params,
      headers,
    }).then((res) => {
      if (res.status === 200) {
        setShowKeyFeatureAtom(res.data.data);
        setTotalRows((preState) => ({
          ...preState,
          showKeyFeature: res.data.meta.total,
        }));
      }
    });
  }
  return {
    createShowReview,
    updateShowReview,
    activeShowReview,
    inactiveShowReview,
    getListShowReview,
    getDetailShowReview,
    getListFaqGroup,
    createBanner,
    addBannerImage,
    updateBannerImage,
    deleteImage,
    updateBanner,
    getListBanner,
    createNews,
    updateNews,
    getListNews,
    getNewsDetail,
    getListNewsGroup,
    updateNewsGroup,
    activeNewGroup,
    inactiveNewGroup,
    createNewsGroup,
    getNewsDetailGroup,
    getListMenu,
    getDetailMenu,
    activeMenu,
    inactiveMenu,
    createMenu,
    updateMenu,
    getDetailShow,
    getListShow,
    createShow,
    updateShow,
    activeShow,
    inactiveShow,
    getListPartners,
    getDetailPartners,
    inactivePartners,
    activePartners,
    updatePartners,
    createPartners,
    createVenues,
    updateVenues,
    activeVenues,
    inactiveVenues,
    getDetailVenues,
    getListVenues,
    getListOffers,
    getDetailOffers,
    inactiveOffers,
    activeOffers,
    updateOffers,
    createOffers,
    getListLocations,
    getDetailLocations,
    inactiveLocations,
    activeLocations,
    updateLocations,
    createLocations,
    getListOfferGroups,
    inactiveOfferGroups,
    getDetailOfferGroups,
    activeOfferGroups,
    updateOfferGroups,
    createOfferGroups,
    getListRecruitment,
    inactiveRecruitment,
    activeRecruitment,
    updateRecruitment,
    createRecruitment,
    getDetailRecruitment,
    getListFaq,
    getDetailFaq,
    inactiveFaq,
    activeFaq,
    updateFaq,
    createFaq,
    createFaqGroup,
    updateFaqGroup,
    activeFaqGroup,
    inactiveFaqGroup,
    getDetailFaqGroup,
    getBannerDetail,
    activeNews,
    inactiveNews,
    getListShowKeyFeature,
    getDetailShowKeyReview,
    inactiveShowKeyReview,
    activeShowKeyReview,
    updateShowKeyReview,
    createShowKeyReview,
    inactiveBanner,
    activeBanner,
    getBannerImageDetail
  };
};
export { useCMSActions };
