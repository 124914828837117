import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Switch,
  Stack,
  Box,
  Select,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import SearchTable2 from "components/Tables/SearchTable2";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { columnsDataShowTime } from "variables/columnsData";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { totalRow } from "state/roles-permissions";
import { AddIcon } from "@chakra-ui/icons";
import { checkForbidden } from "helpers/checkForbidden";
import { BsSearch } from "react-icons/bs";
import { RxReset } from "react-icons/rx";
import { useBookingActions } from "hooks/useBookingActions";
import { showTimeListAtom } from "state/show";
import { STATUS } from "variables/general";
import { HOURS } from "variables/general";
import { MINUTES } from "variables/general";

function DataTableShowTime() {
  const textColor = useColorModeValue("gray.700", "white");
  const showTimeList = useRecoilValue(showTimeListAtom);
  const totalRows = useRecoilValue(totalRow);
  const bookingActions = useBookingActions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCreate, setIsCreate] = useState(true);
  const [showTimeID, setShowTimeID] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [newListShowTime, setNewListShowTime] = useState([]);
  const [refreshPagingDefault, setRefreshPagingDefault] = useState(false);

  const validationSchema = Yup.object().shape({
    hours: Yup.string().required("This field is required"),
    minutes: Yup.string().required("This field is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, reset, setValue } = useForm(
    formOptions
  );
  const {
    register: registerSearch,
    handleSubmit: handleSubmitSearch,
    watch: watchSearch,
    reset: resetSearch,
  } = useForm();
  const { errors, isSubmitting } = formState;
  const toast = useToast();
  const nameSearch = watchSearch("nameSearch");
  const statusSearch = watchSearch("statusSearch");
  useEffect(() => {
    if (showTimeList) {
      const showTimes = showTimeList.map((i) => ({
        ...i,
        typeSwitch: "showTime",
        filters: { name: nameSearch, status: statusSearch },
      }));
      setNewListShowTime(showTimes);
    }
  }, [showTimeList]);
  async function onSubmitSearch({ nameSearch, statusSearch }) {
    setIsFetching(true);
    await bookingActions.getListShowTime(
      { name: nameSearch, status: statusSearch },
      0,
      localStorage && localStorage.getItem("currentPageSizeStore")
        ? localStorage.getItem("currentPageSizeStore")
        : 10
    );
    setRefreshPagingDefault(true)
    setIsFetching(false);
  }
  const recallApi = async (fetchDataOptions) => {
    setIsFetching(true);
    await bookingActions.getListShowTime(
      {
        name: nameSearch,
        status: statusSearch ? statusSearch : 1,
        sort_type: fetchDataOptions.sort_type,
        sort_field: fetchDataOptions.sort_field
      },
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
    setIsFetching(false);
  };
  async function onSubmit({ hours, isActive, minutes }) {
    const isExisted = newListShowTime.find(
      (i) => i.name === `${hours}:${minutes}`
    );
    if (isExisted) {
      toast({
        description: "This time is exist",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return;
    } else {
      const params = {
        name: `${hours}:${minutes}`,
        status: isActive ? 1 : 2,
      };
      try {
        let res;
        if (isCreate) {
          res = await bookingActions.createShowTime(params);
        } else {
          res = await bookingActions.updateShowTime(params, showTimeID);
        }

        if (res.status === 200) {
          onClose();
          reset();
          const message = isCreate
            ? "Create Show Time success"
            : "Update Show Time success";
          toast({
            description: message,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
          await bookingActions.getListShowTime(
            {
              name: nameSearch,
              status: statusSearch ? statusSearch : 1,
            },
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          );
        }
      } catch (err) {
        console.warn(err);
        toast({
          description: err.response.data.meta.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    }
  }

  async function editShowTime(showTimeId) {
    const showTime = await bookingActions.getShowTimeDetail(showTimeId);
    if (showTime) {
      const { name, id, status } = showTime.data.data;
      const part = name.split(':')
      setValue("hours", part[0]);
      setValue("minutes", part[1]);
      setValue("isActive", status === 1 ? true : false);
      setShowTimeID(id);
    }
    setIsCreate(false);
    onOpen();
  }

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="12px">
          <Flex direction="column" display="block">
            <Text
              float="left"
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              mb="6px"
            >
              Show Time Management
            </Text>
            <Stack float="right" direction="row" spacing={4}>
              {checkForbidden(42) && (
                <Button
                  size="sm"
                  onClick={() => {
                    onOpen();
                    setIsCreate(true);
                  }}
                  leftIcon={<AddIcon />}
                  colorScheme="green"
                  variant="outline"
                >
                  Add Show Time
                </Button>
              )}
            </Stack>
            <Modal
              closeOnOverlayClick={false}
              isOpen={isOpen}
              onClose={() => {
                onClose();
                reset();
              }}
              size="2xl"
            >
              <ModalOverlay />
              <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                  <ModalHeader>
                    {isCreate ? "Add New Show Time" : "Edit Show Time"}
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <FormControl isRequired>
                      <Flex gap={2}>
                        <FormControl maxW={"200px"}>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Hours
                          </FormLabel>
                          <Select
                            ms="4px"
                            type="text"
                            size="sm"
                            name="hours"
                            {...register("hours")}
                            width="200px"
                          >
                            {HOURS.map((i, index) => (
                              <option value={i} key={index}>
                                {i}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl maxW={"200px"}>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Minutes
                          </FormLabel>
                          <Select
                            ms="4px"
                            type="text"
                            size="sm"
                            name="minutes"
                            {...register("minutes")}
                            width="200px"
                          >
                            {MINUTES.map((i, index) => (
                              <option value={i} key={index}>
                                {i}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </Flex>
                    </FormControl>
                    <FormControl>
                      <Switch
                        id="isChecked"
                        defaultChecked={isCreate && true}
                        name="isActive"
                        {...register("isActive")}
                        size="sm"
                        ms="4px"
                      >
                        Active
                      </Switch>
                    </FormControl>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      colorScheme="blue"
                      mr={3}
                      type="submit"
                      isLoading={isSubmitting}
                    >
                      {isCreate ? "Create" : "Update"}
                    </Button>
                    <Button
                      onClick={() => {
                        onClose();
                        reset();
                      }}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </form>
            </Modal>
          </Flex>
        </CardHeader>
        <CardBody>
          <Card>
            <form
              id="user-search-form"
              onSubmit={handleSubmitSearch(onSubmitSearch)}
            >
              <Flex
                direction="row"
                alignItems="center"
                mb={3}
                gap={4}
                flexWrap={"wrap"}
              >
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel ms="4px" fontSize="xs" fontWeight="semibold">
                    Name
                  </FormLabel>
                  <Input
                    _placeholder={{ color: "gray.400" }}
                    variant="main"
                    type="text"
                    placeholder="Name"
                    maxLength={250}
                    minW="80px"
                    maxW="185px"
                    size="sm"
                    fontSize="sm"
                    name="nameSearch"
                    {...registerSearch("nameSearch")}
                    _focus={{ borderColor: "blue.500" }}
                  />
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                    Status
                  </FormLabel>
                  <Select
                    ms="4px"
                    type="text"
                    defaultValue="1"
                    size="sm"
                    name="statusSearch"
                    {...registerSearch("statusSearch")}
                    width="100px"
                  >
                    {STATUS.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </Box>
              </Flex>
              <Flex justify="center" align="center" gap="8px" mt={3}>
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<RxReset />}
                    variant="primary"
                    w="88px"
                    h="33px"
                    onClick={() => {
                      resetSearch();
                    }}
                  >
                    Reset
                  </Button>
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<BsSearch />}
                    variant="primary"
                    w="88px"
                    h="33px"
                    type="submit"
                  >
                    Search
                  </Button>
                </Box>
              </Flex>
            </form>
          </Card>
          <SearchTable2
            tableData={newListShowTime}
            columnsData={columnsDataShowTime}
            totalRow={totalRows.showtimes}
            edit={editShowTime}
            actionsActive={["edit"]}
            listPermissions={[43]}
            isFetching={isFetching}
            loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
            refreshPagingDefault={refreshPagingDefault}

          />
        </CardBody>
      </Card>
    </Flex>
  );
}

export default DataTableShowTime;
