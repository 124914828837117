import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Switch,
  Stack,
  Textarea,
  Box,
  Select,
} from "@chakra-ui/react";
import { Select as ReSelect } from "chakra-react-select";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import SearchTable2 from "components/Tables/SearchTable2";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { totalRow } from "state/roles-permissions";
import { AddIcon } from "@chakra-ui/icons";
import { checkForbidden } from "helpers/checkForbidden";
import { useBookingActions } from "hooks/useBookingActions";
import { venueListAtom, locationListAtom } from "state/show";
import { columnsDataVenue } from "variables/columnsData";
import { STATUS } from "variables/general";
import { BsSearch } from "react-icons/bs";
import { RxReset } from "react-icons/rx";

function DataTableVenue() {
  const textColor = useColorModeValue("gray.700", "white");
  const venues = useRecoilValue(venueListAtom);
  const totalRows = useRecoilValue(totalRow);
  const listLocation = useRecoilValue(locationListAtom);
  const bookingActions = useBookingActions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCreate, setIsCreate] = useState(true);
  const [currentVenueID, setCurrentVenueID] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [newListVenue, setNewListVenue] = useState([]);
  const [refreshPagingDefault, setRefreshPagingDefault] = useState(false);

  const chakraStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      p: 0,
      w: "20px",
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: "150px",
    }),
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    location: Yup.object().required("This field is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    control,
  } = useForm(formOptions);
  const {
    register: registerSearch,
    handleSubmit: handleSubmitSearch,
    watch: watchSearch,
    reset: resetSearch,
  } = useForm();
  const { errors, isSubmitting } = formState;
  const toast = useToast();
  const statusSearch = watchSearch("statusSearch");
  const nameSearch = watchSearch("nameSearch");

  useEffect(() => {
    bookingActions.getListShow({ status: 1 }, 0, "");
    bookingActions.getListLocations({ status: 1 }, 0, "");
  }, []);
  useEffect(() => {
    if (venues) {
      const newListVenue = venues.map((i) => ({
        ...i,
        typeSwitch: "venue",
        filters: {
          name: nameSearch,
          status: statusSearch ? statusSearch : 1,
        },
      }));
      setNewListVenue(newListVenue);
    }
  }, [venues]);
  const recallApi = async (fetchDataOptions) => {
    setIsFetching(true);
    await bookingActions.getListVenue(
      {
        name: nameSearch,
        status: statusSearch ? statusSearch : 1,
        sort_type: fetchDataOptions.sort_type,
        sort_field: fetchDataOptions.sort_field,
      },
      0,
      localStorage && localStorage.getItem("currentPageSizeStore")
    );
    setIsFetching(false);
  };
  async function onSubmitSearch({ nameSearch, statusSearch }) {
    setIsFetching(true);
    await bookingActions.getListVenue(
      {
        name: nameSearch,
        status: statusSearch ? statusSearch : 1,
      },
      0,
      localStorage && localStorage.getItem("currentPageSizeStore")
        ? localStorage.getItem("currentPageSizeStore")
        : 10
    );
    setRefreshPagingDefault(true)

    setIsFetching(false);
  }
  async function onSubmit({
    name,
    description,
    isActive,
    name_vi,
    address,
    address_vi,
    description_vi,
    location,
  }) {
    const params = {
      name,
      description,
      name_vi,
      description_vi,
      location_id: location.id,
      address_vi,
      address,
      status: isActive ? 1 : 2,
    };
    try {
      let res;
      if (isCreate) {
        res = await bookingActions.createVenue(params);
      } else {
        res = await bookingActions.updateVenue(params, currentVenueID);
      }

      if (res.status === 200) {
        onClose();
        reset();
        const message = isCreate
          ? "Create Venue success"
          : "Update Venue success";
        toast({
          description: message,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        await bookingActions.getListVenue(
          {
            name: nameSearch,
            status: statusSearch ? statusSearch : 1,
          },
          0,
          localStorage && localStorage.getItem("currentPageSizeStore")
        );
      }
    } catch (err) {
      console.warn(err);
      toast({
        description: err.response.data.meta.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  async function editVenue(venueID) {
    const venueDetail = await bookingActions.getVenueDetail(venueID);
    if (venueDetail) {
      const {
        name,
        name_vi,
        address,
        address_vi,
        description_vi,
        description,
        location_id,
        id,
        status,
      } = venueDetail.data.data;
      setValue("name", name);
      setValue("name_vi", name_vi);
      setValue("address", address);
      setValue("address_vi", address_vi);
      setValue("description_vi", description_vi);
      const location = listLocation.find((i) => i.id === location_id);
      setValue("location", location);
      setValue("description", description);
      setValue("isActive", status === 1 ? true : false);
      setCurrentVenueID(id);
    }
    setIsCreate(false);
    onOpen();
  }
  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="12px">
          <Flex direction="column" display="block">
            <Text
              float="left"
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              mb="6px"
            >
              Venue Management
            </Text>
            <Stack float="right" direction="row" spacing={4}>
              {checkForbidden(115) && (
                <Button
                  size="sm"
                  onClick={() => {
                    onOpen();
                    setIsCreate(true);
                  }}
                  leftIcon={<AddIcon />}
                  colorScheme="green"
                  variant="outline"
                >
                  Add Venue
                </Button>
              )}
            </Stack>
            <Modal
              closeOnOverlayClick={false}
              isOpen={isOpen}
              onClose={() => {
                onClose();
                reset();
              }}
              size="2xl"
            >
              <ModalOverlay />
              <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                  <ModalHeader>
                    {isCreate ? "Add New Venue" : "Edit Venue"}
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <FormControl isRequired>
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Name Venue
                      </FormLabel>
                      <Input
                        fontSize="sm"
                        ms="4px"
                        type="text"
                        placeholder="Name"
                        maxLength={250}
                        mb={
                          errors.name?.message ? "5px" : { lg: "24px", sm: "0" }
                        }
                        size="md"
                        name="name"
                        {...register("name")}
                        isInvalid={errors.name}
                        errorBorderColor="red.300"
                      />
                      <Text color="red" fontSize={14} mb="10px" ml="5px">
                        {errors.name?.message}
                      </Text>
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Name Venue (Vietnamese)
                      </FormLabel>
                      <Input
                        fontSize="sm"
                        ms="4px"
                        type="text"
                        placeholder="Name Venue (Vietnamese)"
                        mb={
                          errors.name_vi?.message
                            ? "5px"
                            : { lg: "24px", sm: "0" }
                        }
                        size="md"
                        name="name_vi"
                        {...register("name_vi")}
                        isInvalid={errors.name_vi}
                        errorBorderColor="red.300"
                      />
                      <Text color="red" fontSize={14} mb="10px" ml="5px">
                        {errors.name_vi?.message}
                      </Text>
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Address
                      </FormLabel>
                      <Input
                        fontSize="sm"
                        ms="4px"
                        type="text"
                        placeholder="Address"
                        mb={
                          errors.address?.message
                            ? "5px"
                            : { lg: "24px", sm: "0" }
                        }
                        size="md"
                        name="address"
                        {...register("address")}
                        isInvalid={errors.address}
                        errorBorderColor="red.300"
                      />
                      <Text color="red" fontSize={14} mb="10px" ml="5px">
                        {errors.address?.message}
                      </Text>
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Address (Vietnamese)
                      </FormLabel>
                      <Input
                        fontSize="sm"
                        ms="4px"
                        type="text"
                        placeholder="Name Venue (Vietnamese)"
                        mb={
                          errors.address_vi?.message
                            ? "5px"
                            : { lg: "24px", sm: "0" }
                        }
                        size="md"
                        name="address_vi"
                        {...register("address_vi")}
                        isInvalid={errors.address_vi}
                        errorBorderColor="red.300"
                      />
                      <Text color="red" fontSize={14} mb="10px" ml="5px">
                        {errors.address_vn?.message}
                      </Text>
                    </FormControl>
                    <FormControl>
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Description
                      </FormLabel>
                      <Textarea
                        rows={4}
                        {...register("description")}
                        maxLength={1000}
                        name="description"
                        mb={
                          errors.description?.message
                            ? "5px"
                            : { lg: "24px", sm: "0" }
                        }
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Description VI
                      </FormLabel>
                      <Textarea
                        rows={4}
                        {...register("description_vi")}
                        name="description_vi"
                        mb={
                          errors.description_vi?.message
                            ? "5px"
                            : { lg: "24px", sm: "0" }
                        }
                      />
                    </FormControl>
                    <FormControl mb={2} isRequired>
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Location Name
                      </FormLabel>
                      <Controller
                        name="location"
                        control={control}
                        render={({ field }) => (
                          <ReSelect
                            {...field}
                            focusBorderColor="blue.500"
                            chakraStyles={chakraStyles}
                            options={listLocation}
                            placeholder="Select Location"
                            getOptionLabel={(i) => i.name}
                            getOptionValue={(option) => option.id}
                            size="sm"
                            closeMenuOnSelect={true}
                          />
                        )}
                      />
                      <Text color="red" fontSize={14} mb="10px" ml="5px">
                        {errors.location?.message}
                      </Text>
                      <Text color="red" ml="5px">
                        {errors.apiError && errors.apiError?.message}
                      </Text>
                    </FormControl>
                    <Text color="red" ml="5px">
                      {errors.apiError && errors.apiError?.message}
                    </Text>
                    <FormControl>
                      <Switch
                        id="isChecked"
                        defaultChecked={isCreate && true}
                        name="isActive"
                        {...register("isActive")}
                        size="sm"
                        ms="4px"
                      >
                        Active
                      </Switch>
                    </FormControl>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      colorScheme="blue"
                      mr={3}
                      type="submit"
                      isLoading={isSubmitting}
                    >
                      {isCreate ? "Create" : "Update"}
                    </Button>
                    <Button
                      onClick={() => {
                        onClose();
                        reset();
                      }}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </form>
            </Modal>
          </Flex>
        </CardHeader>
        <CardBody>
          <Card>
            <form
              id="user-search-form"
              onSubmit={handleSubmitSearch(onSubmitSearch)}
            >
              <Flex
                direction="row"
                alignItems="center"
                mb={3}
                gap={4}
                flexWrap={"wrap"}
              >
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel ms="4px" fontSize="xs" fontWeight="semibold">
                    Venue Name
                  </FormLabel>
                  <Input
                    _placeholder={{ color: "gray.400" }}
                    variant="main"
                    type="text"
                    placeholder="Name"
                    minW="80px"
                    maxW="185px"
                    size="sm"
                    fontSize="sm"
                    name="nameSearch"
                    {...registerSearch("nameSearch")}
                    _focus={{ borderColor: "blue.500" }}
                  />
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                    Status
                  </FormLabel>
                  <Select
                    ms="4px"
                    type="text"
                    defaultValue="1"
                    size="sm"
                    name="statusSearch"
                    {...registerSearch("statusSearch")}
                    width="100px"
                  >
                    {STATUS.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </Box>
              </Flex>
              <Flex justify="center" align="center" gap="8px" mt={3}>
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<RxReset />}
                    variant="primary"
                    w="88px"
                    h="33px"
                    onClick={() => {
                      resetSearch();
                    }}
                  >
                    Reset
                  </Button>
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<BsSearch />}
                    variant="primary"
                    w="88px"
                    h="33px"
                    type="submit"
                  >
                    Search
                  </Button>
                </Box>
              </Flex>
            </form>
          </Card>
          <SearchTable2
            tableData={newListVenue}
            columnsData={columnsDataVenue}
            totalRow={totalRows.venues}
            edit={editVenue}
            actionsActive={["edit"]}
            listPermissions={[116]}
            isFetching={isFetching}
            refreshPagingDefault={refreshPagingDefault}
            loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
          />
        </CardBody>
      </Card>
    </Flex>
  );
}

export default DataTableVenue;
