import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Td,
  Stack,
  Box,
  Select,
  Table,
  Tbody,
  Th,
  Tr,
  Thead,
  Tooltip,
  Icon,
} from "@chakra-ui/react";
import { FaPencilAlt } from "react-icons/fa";
import { Select as ReactSelect } from "chakra-react-select";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import SearchTable2 from "components/Tables/SearchTable2";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { columnsDataTicketPrice } from "variables/columnsData";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { totalRow } from "state/roles-permissions";
import { AddIcon } from "@chakra-ui/icons";
import { checkForbidden } from "helpers/checkForbidden";
import { BsSearch } from "react-icons/bs";
import { RxReset } from "react-icons/rx";
import { useBookingActions } from "hooks/useBookingActions";
import {
  listShowAtom,
  venueListAtom,
  ticketPriceListAtom,
  zoneListAtom,
} from "../../state/show";
import { NumericFormat } from "react-number-format";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

function DataTableTicketPrice() {
  const textColor = useColorModeValue("gray.700", "white");
  const textColor1 = useColorModeValue("blue.500", "white");

  const borderColor = useColorModeValue("gray.200", "gray.600");
  const ticketPriceList = useRecoilValue(ticketPriceListAtom);
  const listShow = useRecoilValue(listShowAtom);
  const venueList = useRecoilValue(venueListAtom);
  const totalRows = useRecoilValue(totalRow);
  const zoneList = useRecoilValue(zoneListAtom);
  const bookingActions = useBookingActions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDetail,
    onOpen: onOpenDetail,
    onClose: onCloseDetail,
  } = useDisclosure();

  const [isCreate, setIsCreate] = useState(true);
  const [currentTicketPrice, setCurrentTicketPrice] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [newTicketPrice, setNewTicketPrice] = useState([]);
  const [listHistoryTicketPrice, setListHistoryTicketPrice] = useState([]);
  const [refreshPagingDefault, setRefreshPagingDefault] = useState(false);

  const chakraStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      p: 0,
      w: "20px",
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: "200px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 20,
    }),
  };
  const validationSchema = Yup.object().shape({
    venue: isCreate
      ? Yup.object().required("This field is required.")
      : undefined,
    show: isCreate
      ? Yup.object().required("This field is required.")
      : undefined,
    from_date: isCreate
      ? Yup.date().required("This field is required.")
      : undefined,
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    control,
    watch,
    setError,
  } = useForm(formOptions);
  const {
    register: registerSearch,
    handleSubmit: handleSubmitSearch,
    watch: watchSearch,
    reset: resetSearch,
  } = useForm();
  const { errors, isSubmitting } = formState;
  const toast = useToast();
  const showSearch = watchSearch("showSearch");
  const venueSearch = watchSearch("venueSearch");
  useEffect(async () => {
    bookingActions.getListShow({ status: 1 }, 0, "");
    bookingActions.getListVenue({ status: 1 }, 0, "");
    bookingActions.getListZones({ status: 1 }, 0, "");
  }, []);
  useEffect(() => {
    if (ticketPriceList) {
      const newListTicketPrice = ticketPriceList.map((i) => ({
        ...i,
        filters: {
          show_id: showSearch,
          venue_id: venueSearch,
        },
        typeSwitch: "ticketPrice",
      }));
      setNewTicketPrice(newListTicketPrice);
    }
  }, [ticketPriceList]);

  async function onSubmitSearch({ showSearch, venueSearch }) {
    setIsFetching(true);
    await bookingActions.getListTicketPrice(
      { show_id: showSearch, venue_id: venueSearch },
      0,
      localStorage && localStorage.getItem("currentPageSizeStore")
        ? localStorage.getItem("currentPageSizeStore")
        : 10
    );
    setRefreshPagingDefault(true)

    setIsFetching(false);
  }
  const recallApi = async (fetchDataOptions) => {
    setIsFetching(true);
    await bookingActions.getListTicketPrice(
      {
        sort_type: fetchDataOptions.sort_type,
        sort_field: fetchDataOptions.sort_field,
        show_id: showSearch,
        venue_id: venueSearch
      },
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
    setIsFetching(false);
  };
  async function onSubmit({ venue, show, from_date, official_price }) {
    try {
      let res;
      if (isCreate) {
        let isHaveError = false;
        const prices = zoneList.map((i) => ({
          zone_id: i.id,
          official_price: watch(`zone_id_${i.id}`)
            ? parseInt(watch(`zone_id_${i.id}`).replace(/^0+|,/g, ""))
            : 0,
        }));
        prices.forEach((i) => {
          if (!i.official_price) {
            setError(`zone_id_${i.zone_id}`, {
              message: "This field is required.",
            });
            isHaveError = true;
          }
        });
        if (isHaveError) {
          return;
        }
        const params = {
          venue_id: venue.id,
          show_id: show.id,
          from_date: from_date.toISOString(),
          prices,
        };
        res = await bookingActions.createTicketPrice(params);
      } else {
        if (!watch("official_price")) {
          setError("official_price", {
            message: "This field is required.",
          });
          return;
        }
        const params = {
          official_price: parseInt(
            official_price.toString().replace(/^0+|,/g, "")
          ),
        };
        res = await bookingActions.updateTicketPrice(
          params,
          currentTicketPrice.id
        );
      }

      if (res.status === 200) {
        onClose();
        reset();
        const message = isCreate
          ? "Create Ticket Price success"
          : "Update Ticket Price success";
        toast({
          description: message,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (err) {
      console.warn(err);
      toast({
        description: err?.response?.data?.meta?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
    bookingActions.getListTicketPrice({}, 0, 10);
  }

  async function editTicketPrice(ticketPriceID) {
    const ticketPriceDetail = await bookingActions.getTicketPriceDetail(
      ticketPriceID
    );
    if (ticketPriceDetail) {
      const { official_price } = ticketPriceDetail.data.data;
      setValue("official_price", official_price);
      setCurrentTicketPrice(ticketPriceDetail.data.data);
    }
    setIsCreate(false);
    onOpen();
    onCloseDetail();
  }

  async function showDetailTicketPrice(ticketPriceID) {
    const ticketPriceDetail = await bookingActions.getTicketPriceDetail(
      ticketPriceID
    );
    if (ticketPriceDetail) {
      const { show_id, venue_id, zone_id } = ticketPriceDetail.data.data;
      const listHistoryTicketPrice = await bookingActions.getListHistoryTicketPrice(
        {
          show_id,
          venue_id,
          zone_id,
        }
      );
      setListHistoryTicketPrice(listHistoryTicketPrice);
    }
    onOpenDetail();
  }

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="12px">
          <Flex direction="column" display="block">
            <Text
              float="left"
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              mb="6px"
            >
              Ticket Price Management
            </Text>
            <Stack float="right" direction="row" spacing={4}>
              {checkForbidden(103) && (
                <Button
                  size="sm"
                  onClick={() => {
                    onOpen();
                    setIsCreate(true);
                  }}
                  leftIcon={<AddIcon />}
                  colorScheme="green"
                  variant="outline"
                >
                  Add Ticket Price
                </Button>
              )}
            </Stack>

            <Modal
              closeOnOverlayClick={false}
              isOpen={isOpenDetail}
              onClose={() => {
                onCloseDetail();
              }}
              size="3xl"
            >
              <ModalOverlay />
              <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                  <ModalHeader>Ticket Price</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <Table variant="simple" color={textColor}>
                      <Thead>
                        <Tr my=".8rem" pl="0px" color="gray.400">
                          <Th borderColor={borderColor} p="16px 12px">
                            #
                          </Th>
                          <Th borderColor={borderColor} p="16px 12px">
                            Start Date
                          </Th>
                          <Th borderColor={borderColor} p="16px 12px">
                            End Date
                          </Th>
                          <Th borderColor={borderColor} p="16px 12px">
                            Price
                          </Th>
                          <Th borderColor={borderColor} p="16px 12px">
                            Action
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {listHistoryTicketPrice?.map((item, index) => (
                          <Tr key={index}>
                            <Td
                              borderColor={borderColor}
                              p="16px 12px"
                              borderRight="1px solid gray"
                            >
                              {index + 1}
                            </Td>
                            <Td
                              borderColor={borderColor}
                              p="16px 12px"
                              borderRight="1px solid gray"
                            >
                              <Text fontSize="xs" color={textColor}>
                                {moment(item?.from_date).format("DD/MM/YYYY")}
                              </Text>
                            </Td>
                            <Td
                              borderColor={borderColor}
                              p="16px 12px"
                              borderRight="1px solid gray"
                            >
                              {item.to_date && (
                                <Text fontSize="xs" color={textColor}>
                                  {moment(item?.to_date).format("DD/MM/YYYY")}
                                </Text>
                              )}
                            </Td>
                            <Td
                              borderColor={borderColor}
                              p="16px 12px"
                              borderRight="1px solid gray"
                            >
                              <Text fontSize="xs" color={textColor}>
                                {item.official_price.toLocaleString("en-US")}
                              </Text>
                            </Td>
                            <Td borderColor={borderColor} p="16px 12px">
                              {!item.to_date && (
                                <Tooltip hasArrow label="Edit" bg="gray.400">
                                  <Button
                                    className="icon-btn-no-shadow"
                                    p="0px"
                                    variant="no-effects"
                                    onClick={() => editTicketPrice(item.id)}
                                  >
                                    <Flex
                                      color={textColor1}
                                      cursor="pointer"
                                      align="center"
                                      p="0px"
                                    >
                                      <Icon as={FaPencilAlt} me="0px" />
                                    </Flex>
                                  </Button>
                                </Tooltip>
                              )}
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      onClick={() => {
                        onCloseDetail();
                      }}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </form>
            </Modal>

            <Modal
              closeOnOverlayClick={false}
              isOpen={isOpen}
              onClose={() => {
                onClose();
                reset();
              }}
              size="3xl"
            >
              <ModalOverlay />
              <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                  <ModalHeader>
                    {isCreate ? "Add New Ticket Price" : "Edit Ticket Price"}
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <Flex gap={2}>
                      <FormControl isRequired>
                        <FormLabel ms="4px" fontSize="sm" fontWeight="bold">
                          Venue
                        </FormLabel>
                        {isCreate ? (
                          <>
                            <Controller
                              name="venue"
                              control={control}
                              render={({ field }) => (
                                <ReactSelect
                                  {...field}
                                  focusBorderColor="blue.500"
                                  chakraStyles={chakraStyles}
                                  options={venueList}
                                  placeholder="Select Venue"
                                  getOptionLabel={(i) => i.name}
                                  getOptionValue={(option) => option.id}
                                  size="sm"
                                  closeMenuOnSelect={true}
                                />
                              )}
                            />
                            <Text color="red" ml="5px">
                              {errors.venue && errors.venue?.message}
                            </Text>
                          </>
                        ) : (
                          <Text pl={2}>{currentTicketPrice.venue.name}</Text>
                        )}
                      </FormControl>
                      <FormControl>
                        <FormLabel ms="4px" fontSize="sm" fontWeight="bold">
                          Show Name
                        </FormLabel>
                        {isCreate ? (
                          <>
                            <Controller
                              name="show"
                              control={control}
                              render={({ field }) => (
                                <ReactSelect
                                  {...field}
                                  focusBorderColor="blue.500"
                                  chakraStyles={chakraStyles}
                                  options={listShow}
                                  placeholder="Select Show"
                                  getOptionLabel={(i) => i.name}
                                  getOptionValue={(option) => option.id}
                                  size="sm"
                                  closeMenuOnSelect={true}
                                />
                              )}
                            />
                            <Text color="red" ml="5px">
                              {errors.show && errors.show?.message}
                            </Text>
                          </>
                        ) : (
                          <Text pl={2}>{currentTicketPrice.show.name}</Text>
                        )}

                        <Text color="red" ml="5px">
                          {errors.apiError && errors.apiError?.message}
                        </Text>
                      </FormControl>
                    </Flex>
                    <Box
                      direction="row"
                      mt={4}
                      alignItems="center"
                      display="block"
                    >
                      <FormLabel fontWeight="bold" fontSize="xs" ms="4px">
                        Start Date
                      </FormLabel>
                      {isCreate ? (
                        <>
                          <Controller
                            name="from_date"
                            control={control}
                            render={({ field }) => (
                              <ReactDatePicker
                                customInput={<Input />}
                                className="booking-date-range-search"
                                onChange={(value) => field.onChange(value)}
                                selected={field?.value}
                                dateFormat="dd/MM/yyyy"
                                showYearDropdown
                                minDate={new Date()}
                              />
                            )}
                          />
                          <Text color="red" ml="5px">
                            {errors.from_date && errors.from_date?.message}
                          </Text>
                        </>
                      ) : (
                        <Text pl={2}>
                          {moment(currentTicketPrice.from_date).format(
                            "DD/MM/YYYY"
                          )}
                        </Text>
                      )}
                    </Box>
                    <Flex mt={4} direction="column" gap={4}>
                      {isCreate ? (
                        zoneList.map((i, index) => (
                          <Box
                            direction="row"
                            alignItems="center"
                            display="block"
                            key={index}
                          >
                            <FormLabel
                              ms="4px"
                              fontSize="xs"
                              fontWeight="semibold"
                            >
                              {i.name}
                            </FormLabel>

                            <Controller
                              name={`zone_id_${i.id}`}
                              control={control}
                              render={({ field }) => (
                                <NumericFormat
                                  customInput={Input}
                                  thousandSeparator=","
                                  fontSize="sm"
                                  ms="4px"
                                  size="sm"
                                  defaultValue={0}
                                  {...field}
                                />
                              )}
                            />
                            <Text color="red" ml="5px">
                              {errors[`zone_id_${i.id}`] &&
                                errors[`zone_id_${i.id}`]?.message}
                            </Text>
                          </Box>
                        ))
                      ) : (
                        <Flex direction="column">
                          <Text pl={2} fontSize={"bold"}>
                            {currentTicketPrice.zone.name}
                          </Text>
                          <Controller
                            name="official_price"
                            control={control}
                            render={({ field }) => (
                              <NumericFormat
                                customInput={Input}
                                thousandSeparator=","
                                fontSize="sm"
                                ms="4px"
                                size="sm"
                                defaultValue={0}
                                {...field}
                                maxW="250px"
                              />
                            )}
                          />
                          <Text color="red" ml="5px">
                            {errors.official_price &&
                              errors.official_price?.message}
                          </Text>
                        </Flex>
                      )}
                    </Flex>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      colorScheme="blue"
                      mr={3}
                      type="submit"
                      isLoading={isSubmitting}
                    >
                      {isCreate ? "Create" : "Update"}
                    </Button>
                    <Button
                      onClick={() => {
                        onClose();
                        reset();
                      }}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </form>
            </Modal>
          </Flex>
        </CardHeader>
        <CardBody>
          <Card>
            <form
              id="user-search-form"
              onSubmit={handleSubmitSearch(onSubmitSearch)}
            >
              <Flex
                direction="row"
                alignItems="center"
                mb={3}
                gap={4}
                flexWrap={"wrap"}
              >
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel ms="4px" fontSize="xs" fontWeight="semibold">
                    Show
                  </FormLabel>
                  <Select
                    ms="4px"
                    type="text"
                    defaultValue="0"
                    size="sm"
                    id="showSearch"
                    name="showSearch"
                    {...registerSearch("showSearch")}
                    width="200px"
                  >
                    <option value="">All Show</option>
                    {listShow.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                    Venue
                  </FormLabel>
                  <Select
                    ms="4px"
                    type="text"
                    size="sm"
                    id={"venueSearch"}
                    name="venueSearch"
                    {...registerSearch("venueSearch")}
                  >
                    <option value="">All Venue</option>
                    {venueList.map((i) => (
                      <option key={i.id} value={i.id}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </Box>
              </Flex>
              <Flex justify="center" align="center" gap="8px" mt={3}>
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<RxReset />}
                    variant="primary"
                    w="88px"
                    h="33px"
                    onClick={() => {
                      resetSearch();
                      onSubmitSearch(showSearch, venueSearch);
                    }}
                  >
                    Reset
                  </Button>
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<BsSearch />}
                    variant="primary"
                    w="88px"
                    h="33px"
                    type="submit"
                  >
                    Search
                  </Button>
                </Box>
              </Flex>
            </form>
          </Card>
          <SearchTable2
            tableData={newTicketPrice}
            columnsData={columnsDataTicketPrice}
            totalRow={totalRows.ticketPrices}
            edit={editTicketPrice}
            actionsActive={["edit", "detail"]}
            showDetail={showDetailTicketPrice}
            listPermissions={[104]}
            isFetching={isFetching}
            loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
            refreshPagingDefault={refreshPagingDefault}
          />
        </CardBody>
      </Card>
    </Flex>
  );
}

export default DataTableTicketPrice;
