import {
  Flex,
  Text,
  useToast,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Switch,
  Stack,
  Checkbox,
  Box,
  Select,
  CheckboxGroup,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { columnsDataVoucher } from "variables/columnsData";
import { totalRow } from "state/roles-permissions";
import { useForm, Controller } from "react-hook-form";
import { useBookingActions } from "hooks/useBookingActions";
import {
  listChannelAtom,
  listUnitAtom,
  listShowAtom,
  toolTypesAtom,
  venueListAtom,
  paymentStatusListAtom,
  showsScheduleAtom,
  paymentMethodListAtom,
} from "state/show";
import { vouchersTemplateAtom } from "state/recoil-root";

import { Select as ReactSelect } from "chakra-react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useVoucherActions } from "hooks/useVoucherActions";
import { vouchersAtom } from "state/vouchers";
import DatePickerInput from "components/CustomDateInput/CustomDateInput";
import { useHistory } from "react-router-dom";
import { parseISO } from "date-fns";
import { AddIcon } from "@chakra-ui/icons";
import moment from "moment";
import { checkForbidden } from "helpers/checkForbidden";
import { RxReset } from "react-icons/rx";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BsSearch } from "react-icons/bs";
import SearchTable2 from "components/Tables/SearchTable2";
import ListShow from "components/ListShow/ListShow";
import { authAtom } from "state/auth";

function DataTableIssueVoucher() {
  const auth = useRecoilValue(authAtom);
  const history = useHistory();
  const bookingActions = useBookingActions();
  const totalRows = useRecoilValue(totalRow);
  const listChannel = useRecoilValue(listChannelAtom);
  const listToolTypes = useRecoilValue(toolTypesAtom);
  const listUnit = useRecoilValue(listUnitAtom);
  const listVoucherTemplate = useRecoilValue(vouchersTemplateAtom);
  const listShow = useRecoilValue(listShowAtom);
  const venueList = useRecoilValue(venueListAtom);
  const voucherActions = useVoucherActions();
  const vouchers = useRecoilValue(vouchersAtom);
  const showsSchedule = useRecoilValue(showsScheduleAtom);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCreate, setIsCreate] = useState(true);
  const [currentID, setCurrentID] = useState();
  const [groupedShowData, setGroupedShowData] = useState([]);
  const [checkedDefault, setCheckedDefault] = useState([]);
  const listPaymentMethod = useRecoilValue(paymentMethodListAtom);
  const listPaymentStatus = useRecoilValue(paymentStatusListAtom);
  const [isExportExcel, setIsExportExcel] = useState(false);
  const [validateSellVoucher, setValidateSellVoucher] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [newVouchers, setNewVouchers] = useState([]);
  const [timeCheckedAll, setTimeCheckedAll] = useState([]);
  const [listShowForEdit, setListShowForEdit] = useState([]);
  const [listZoneForEdit, setListZoneForEdit] = useState([]);
  const [refreshPagingDefault, setRefreshPagingDefault] = useState(false);
  const [listVoucherTypeSearch, _] = useState([
    { id: "0", name: "All Voucher Type" },
    { id: "1", name: "Issue Voucher" },
    { id: "2", name: "Sell Voucher" },
  ]);
  const [listVoucherType, setListVoucherType] = useState([
    { id: "1", name: "Issue Voucher" },
    { id: "2", name: "Sell Voucher" },
  ]);
  const [salePrice, setSalePrice] = useState(0);
  const chakraStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      p: 0,
      w: "20px",
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: "200px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 20,
    }),
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    name_vi: Yup.string().required("This field is required"),
    quantity: Yup.string().required("This field is required"),
    min_los: Yup.number().required("This field is required"),
    max_los: Yup.number().required("This field is required"),
    toolType: Yup.object().required("This field is required"),
    channel: Yup.object().required("This field is required"),
    unit_id: Yup.object().required("This field is required"),
    from_date: Yup.string().required("This field is required"),
    to_date: Yup.string().required("This field is required"),
    to_showdate: Yup.string().required("This field is required"),
    from_showdate: Yup.string().required("This field is required"),
    customer_name:
      validateSellVoucher && Yup.string().required("This field is required"),
    payment_method:
      validateSellVoucher && Yup.object().required("This field is required"),
    payment_status:
      validateSellVoucher && Yup.object().required("This field is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    setError,
    formState,
    reset,
    control,
    watch,
    setValue,
  } = useForm(formOptions);
  const {
    register: registerSearch,
    handleSubmit: handleSubmitSearch,
    watch: watchSearch,
    control: controlSearch,
    reset: resetSearch,
    setValue: setValueSearch,
  } = useForm();
  const { errors, isSubmitting } = formState;
  const channel = watch("channel");
  const unit = watch("unit_id");
  const voucherTemplate = watch("voucher_template_id");
  const listShowChoose = watch("listShow");
  const to_showdate = watch("to_showdate");
  const from_showdate = watch("from_showdate");
  const exclude = watch("exclude");
  const voucher_type_choose = watch("voucher_type");
  //search form
  const nameSearch = watchSearch("nameSearch");
  const statusSearch = watchSearch("statusSearch");
  const venueSearch = watchSearch("venueSearch");
  const unitSearch = watchSearch("unitSearch");
  const channelSearch = watchSearch("channelSearch");
  const showSearch = watchSearch("showSearch");
  const voucherTypeSearch = watchSearch("voucherTypeSearch");
  const toolTypeSearch = watchSearch("toolTypeSearch");
  const excludes = watch("excludes");
  const voucher = watch("voucher");

  const toast = useToast();
  useEffect(() => {
    if (channelSearch) {
      bookingActions.getListUnits({ channel_id: channelSearch }, 0, "");
    }
  }, [channelSearch]);
  useEffect(() => {
    if (voucher_type_choose?.id === "2") {
      setValidateSellVoucher(true);
    } else {
      setValidateSellVoucher(false);
    }
  }, [voucher_type_choose]);

  useEffect(() => {
    bookingActions.getListChannel({ status: 1 }, 0, "");
    voucherActions.getAllVouchersTemplate({ status: 1 }, 0, "");
    bookingActions.getListToolTypes({ status: 1 }, 0, "");
    bookingActions.getListShow({ status: 1 }, 0, "");
    bookingActions.getListVenue({ status: 1 }, 0, "");
    bookingActions.getListZones({ status: 1 }, 0, "");
    bookingActions.getListPaymentStatus({ status: 1 }, 0, "");
    bookingActions.getListPaymentMethod({ status: 1 }, 0, "");
  }, []);
  const recallApi = async (fetchDataOptions) => {
    await voucherActions.getAllVouchers(
      {
        name: nameSearch,
        status: statusSearch ? statusSearch : 1,
        show_id: showSearch,
        venue_id: venueSearch,
        channel_id: channelSearch,
        unit_id: unitSearch ? unitSearch.id : "",
        tool_type_id: toolTypeSearch,
        voucher_type_id: voucherTypeSearch ? parseInt(voucherTypeSearch.id) : 0,
        sort_type: fetchDataOptions.sort_type,
        sort_field: fetchDataOptions.sort_field,
      },
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
  };
  useEffect(() => {
    if (vouchers) {
      const newVouchers = vouchers.map((i) => ({
        ...i,
        typeSwitch: "voucher",
        nameChannel: i.Unit.channel.name,
        filters: {
          name: nameSearch,
          status: statusSearch ? statusSearch : 1,
          show_id: showSearch,
          venue_id: venueSearch,
          channel_id: channelSearch,
          unit_id: unitSearch,
          tool_type_id: toolTypeSearch,
          voucher_type_id: voucherTypeSearch
            ? parseInt(voucherTypeSearch.id)
            : 0,
        },
      }));
      setNewVouchers(newVouchers);
    }
  }, [vouchers]);

  useEffect(() => {
    if (to_showdate && from_showdate && isCreate) {
      const listShowID = listShowChoose && listShowChoose.join(",");
      bookingActions.getShowsSchedule({
        show_id: listShowID || "",
        venue_id: "",
        status: "Active",
        from_date: from_showdate,
        to_date: to_showdate,
      });
    }
  }, [to_showdate, from_showdate]);
  useEffect(() => {
    if (showsSchedule && showsSchedule.length > 0 && isCreate) {
      const groupedData = showsSchedule.reduce((result, item) => {
        const showTime = item.show_time;
        if (!result[showTime]) {
          result[showTime] = [];
        }
        result[showTime].push(item);

        return result;
      }, {});
      setGroupedShowData(groupedData);
      let checked = [];
      groupedData &&
        Object.entries(groupedData).forEach(([key, value]) => {
          value.forEach((item) => {
            checked.push(String(item.id));
          });
        });
      setCheckedDefault(checked);
      setValue("excludes", checked);
    }
  }, [showsSchedule]);
  useEffect(() => {
    if (channel) {
      setValue("unit_id", null);
      bookingActions.getListUnits({ channel_id: channel.id }, 0, "");
    }
  }, [channel]);
  useEffect(() => {
    if (voucherTemplate) {
      let shows = [];
      let venues = [];
      let displayVenues = [];
      let showID = -1;
      let preShow;
      let showVenueID = "";
      let index = 1;

      if (voucherTemplate) {
        voucherTemplate.voucher_configs.map((item) => {
          //first row || (the same show and not including in displayVenue) => add current venue
          if (
            showID == -1 ||
            (showID == item.show_id &&
              displayVenues.indexOf(
                item.Show.id.toString() + "_" + item.Venue.id.toString()
              ) == -1)
          ) {
            venues.push(item.Venue);
            showVenueID =
              item.Show.id.toString() + "_" + item.Venue.id.toString();
            displayVenues.push(showVenueID);
          }

          //---case 1: last item and n show
          if (
            index == voucherTemplate.voucher_configs.length &&
            showID != -1 &&
            showID != item.show_id
          ) {
            //1.1 push preShow
            shows.push({
              id: preShow.id.toString(),
              name: preShow.name.toString(),
              venue: venues,
              displayVenue: displayVenues,
            });
            venues = [];
            displayVenues = [];
            //1.2.1 add current venue
            venues.push(item.Venue);
            showVenueID =
              item.Show.id.toString() + "_" + item.Venue.id.toString();
            displayVenues.push(showVenueID);
            //1.2.2 push current show
            shows.push({
              id: item.Show.id.toString(),
              name: item.Show.name.toString(),
              venue: venues,
              displayVenue: displayVenues,
            });
          } else if (
            index == voucherTemplate.voucher_configs.length ||
            (showID != -1 && showID != item.show_id)
          ) {
            //---case 2: one row(one show) || (n row && n show)

            //2.1.0: one row(one show) => push current show
            let sID = item.Show.id.toString();
            let sName = item.Show.name.toString();
            //2.2.1: n show => push preShow
            if (showID != -1 && showID != item.show_id) {
              sID = preShow.id.toString();
              sName = preShow.name.toString();
            }

            shows.push({
              id: sID,
              name: sName,
              venue: venues,
              displayVenue: displayVenues,
            });
            venues = [];
            displayVenues = [];
            //2.2.2: n show => add current venue
            if (showID != -1 && showID != item.show_id) {
              venues.push(item.Venue);
              showVenueID =
                item.Show.id.toString() + "_" + item.Venue.id.toString();
              displayVenues.push(showVenueID);
            }
          }

          showID = item.show_id;
          preShow = item.Show;
          index = index + 1;
        });
      }
      setListShowForEdit(shows);
      const zones = voucherTemplate.voucher_configs
        ? voucherTemplate.voucher_configs
        : [];
      setListZoneForEdit(zones);
    }
  }, [voucherTemplate, voucher_type_choose]);
  async function onSubmitSearch({
    nameSearch,
    statusSearch,
    showSearch,
    venueSearch,
    channelSearch,
    unitSearch,
    toolTypeSearch,
    voucherTypeSearch,
  }) {
    setIsFetching(true);
    if (isExportExcel) {
      setIsExportExcel(false);
      const response = await voucherActions.exportVoucherList(
        {
          name: nameSearch,
          status: statusSearch,
          show_id: showSearch,
          venue_id: venueSearch,
          channel_id: channelSearch,
          unit_id: unitSearch.id,
          tool_type_id: toolTypeSearch,
          voucher_type_id: voucherTypeSearch
            ? parseInt(voucherTypeSearch.id)
            : 0,
        },
        0,
        localStorage && localStorage.getItem("currentPageSizeStore")
          ? localStorage.getItem("currentPageSizeStore")
          : 10
      );
      const outputFilename = `vouchers_${Date.now()}.xlsx`;
      const url = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
    } else {
      await voucherActions.getAllVouchers(
        {
          name: nameSearch,
          status: statusSearch,
          show_id: showSearch,
          venue_id: venueSearch,
          channel_id: channelSearch,
          unit_id: unitSearch ? unitSearch.id : "",
          tool_type_id: toolTypeSearch,
          voucher_type_id: voucherTypeSearch
            ? parseInt(voucherTypeSearch.id)
            : 0,
        },
        0,
        localStorage && localStorage.getItem("currentPageSizeStore")
          ? localStorage.getItem("currentPageSizeStore")
          : 10
      );
    }
    setRefreshPagingDefault(true)
    setIsFetching(false);
  }
  async function onSubmit({
    name,
    name_vi,
    toolType,
    unit_id,
    quantity,
    min_los,
    max_los,
    from_date,
    to_date,
    from_showdate,
    to_showdate,
    status,
    voucher_type,
    customer_name,
    payment_method,
    payment_status,
    excludes,
    voucher_template_id,
  }) {
    try {
      const payment_method_val = payment_method
        ? parseInt(payment_method.id)
        : 0;
      const payment_status_val = payment_status
        ? parseInt(payment_status.id)
        : 0;
      if (isCreate) {
        if (voucher_type && voucher_type.id === "2" && customer_name == "") {
          setError("customer_name", {
            message: "This field is required.",
          });
          return false;
        }
        if (voucher_type && voucher_type.id === "2" && !payment_method) {
          setError("payment_method", {
            message: "This field is required.",
          });
          return false;
        }
        if (voucher_type && voucher_type.id === "2" && !payment_status) {
          setError("payment_status", {
            message: "This field is required.",
          });
          return false;
        }

        let show_schedule_detail_id = [];
        if (exclude) {
          if (excludes.length > 0) {
            const uniqueValues = checkedDefault
              .filter((value) => !excludes.includes(value))
              .concat(
                excludes.filter((value) => !checkedDefault.includes(value))
              );
            const parseExcludes = uniqueValues.map((i) => parseInt(i));
            show_schedule_detail_id = parseExcludes;
          }
        }

        const res = await voucherActions.createVoucher({
          name,
          name_vi,
          tool_type: toolType.id,
          unit_id: unit_id.id,
          quantity: parseInt(quantity),
          min_los,
          max_los,
          from_date: new Date(from_date).toISOString(),
          to_date: new Date(to_date).toISOString(),
          from_showdate: new Date(from_showdate).toISOString(),
          to_showdate: new Date(to_showdate).toISOString(),
          status: status ? 1 : 2,
          show_schedule_detail_id,
          voucher_type: parseInt(voucher_type.id),
          customer_name: customer_name || "",
          payment_method: payment_method_val,
          payment_status: payment_status_val,
          voucher_template_id: voucher_template_id.id,
        });
        if (res.status === 200) {
          toast({
            description: `Create Voucher successful`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
      } else {
        await voucherActions.updateVoucher(
          {
            name,
            name_vi,
            tool_type: toolType.id,
            min_los,
            max_los,
            voucher_type: parseInt(voucher_type),
            customer_name: customer_name || "",
            payment_method: payment_method_val,
            payment_status: payment_status_val,
            voucher_template_id: voucher_template_id.id,
          },
          currentID
        );
        toast({
          description: `Update Voucher successful`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
      onClose();
      reset();
    } catch (err) {
      console.warn(err, "err");
    } finally {
      await voucherActions.getAllVouchers(
        {
          name: nameSearch,
          status: statusSearch,
          show_id: showSearch,
          venue_id: venueSearch,
          channel_id: channelSearch,
          unit_id: unitSearch ? unitSearch.id : "",
          tool_type_id: toolTypeSearch,
          voucher_type_id: voucherTypeSearch
            ? parseInt(voucherTypeSearch.id)
            : 0,
        },
        0,
        10
      );
      setIsCreate(true)
    }
  }
  async function showDetail(val) {
    const {
      data: { data },
    } = await voucherActions.getVoucherDetail(val);
    onOpen();
    setIsCreate(false);
    setValue("name", data.name);
    setValue("name_vi", data.name_vi);
    setValue("toolType", data.ToolType);
    setValue("voucher_type", data.voucher_type.toString());
    setValue("customer_name", data.customer_name);
    setValue("payment_method", data.PaymentMethod);
    setValue("payment_status", data.PaymentStatus);
    setValue("quantity", data.quantity);
    setValue("min_los", data.min_los);
    setValue("max_los", data.max_los);
    setValue("from_date", parseISO(data.from_date, 1));
    setValue("to_date", parseISO(data.to_date), 1);
    setValue("from_showdate", parseISO(data.from_showdate), 1);
    setValue("to_showdate", parseISO(data.to_showdate), 1);
    setValue("status", data.status);
    setValue("channel", data.Unit.channel);
    setValue("unit_id", data.Unit);
    const res = await bookingActions.getShowsSchedule({
      status: "Active",
      from_date: parseISO(data.from_showdate),
      to_date: parseISO(data.to_showdate),
    });
    if (
      data.voucher_showtime_excludes &&
      data.voucher_showtime_excludes.length > 0
    ) {
      setValue("exclude", true);

      const excludeShowScheduleDetails = data.voucher_showtime_excludes.map(
        (i) => i.show_schedule_detail_id.toString()
      );
      let checked = [];
      const groupedData = res.reduce((result, item) => {
        const showTime = item.show_time;
        if (!result[showTime]) {
          result[showTime] = [];
        }
        result[showTime].push(item);

        return result;
      }, {});

      setGroupedShowData(groupedData);
      groupedData &&
        Object.entries(groupedData).forEach(([key, value]) => {
          value.forEach((item) => {
            checked.push(String(item.id));
          });
        });
      setCheckedDefault(checked);
      const uniqueValues = checked
        .filter((value) => !excludeShowScheduleDetails.includes(value))
        .concat(
          excludeShowScheduleDetails.filter((value) => !checked.includes(value))
        );
      setValue("excludes", uniqueValues);
    } else {
      setValue("exclude", false);
    }
    setValue("voucher_template_id", data.voucher_template);
    setCurrentID(data.id);
  }
  useEffect(() => {
    if (excludes && excludes.length > 0) {
      const matchingKeys = Object.keys(groupedShowData).filter((key) =>
        groupedShowData[key].every((item) =>
          excludes.includes(item.id.toString())
        )
      );
      setTimeCheckedAll(matchingKeys);
    }
  }, [excludes]);
  const checkedAllShowTime = (val, key) => {
    const showTimeID = val.map((i) => i.id.toString());
    setValue("excludes", [...new Set([...excludes, ...showTimeID])]);
    setTimeCheckedAll([...timeCheckedAll, ...[key]]);
  };
  const removeShowTime = (val, key) => {
    const filtered = excludes.filter(
      (idA) => !val.some((itemB) => itemB.id.toString() === idA)
    );
    const newTimeCheckedAll = timeCheckedAll.filter((i) => i !== key);
    setValue("excludes", filtered);
    setTimeCheckedAll(newTimeCheckedAll);
  };
  const VoucherIssueStatus = [
    { Id: "0", Name: "All" },
    { Id: "1", Name: "Active" },
    { Id: "2", Name: "InActive" },
  ];

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px">
          <Flex direction="column" display="block">
            <Text float="left" fontSize="lg" fontWeight="bold" mb="6px">
              Voucher
            </Text>
            <Stack float="right" direction="row" spacing={4}>
              {(checkForbidden(28)) && (
                <Button
                  size="sm"
                  onClick={() => {
                    onOpen();
                    setIsCreate(true);
                    setValue("min_los", 1);
                    setValue("max_los", 1);
                    let test = [...listVoucherType];
                    if (!auth.permission.includes(28)) {
                      test = test.filter((i) => i.id !== "1");
                    }
                    if (!auth.permission.includes(31)) {
                      test = test.filter((i) => i.id !== "2");
                    }
                    setListVoucherType(test);
                  }}
                  leftIcon={<AddIcon />}
                  colorScheme="green"
                  variant="outline"
                >
                  Add Voucher
                </Button>
              )}
            </Stack>
            <Modal
              closeOnOverlayClick={false}
              isOpen={isOpen}
              onClose={() => {
                onClose();
                reset();
                setListVoucherType([
                  { id: "1", name: "Issue Voucher" },
                  { id: "2", name: "Sell Voucher" },
                ]);
              }}
              size="3xl"
            >
              <ModalOverlay />
              <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                  <ModalHeader>
                    {isCreate ? "Create Voucher" : "View Voucher"}
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <Grid gap="16px" templateColumns="repeat(2, 1fr)" mb={3}>
                      <Flex direction="column">
                        <FormControl>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Name
                          </FormLabel>
                          <Input
                            fontSize="sm"
                            ms="4px"
                            type="text"
                            placeholder="Name"
                            maxLength={250}
                            size="md"
                            name="name"
                            {...register("name")}
                            isInvalid={errors.name}
                            errorBorderColor="red.300"
                            isDisabled={!isCreate}
                          />
                          <Text color="red" fontSize={14} mb="10px" ml="5px">
                            {errors.name?.message}
                          </Text>
                        </FormControl>
                      </Flex>
                      <Flex direction="column">
                        <FormControl>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Name(Vietnamese)
                          </FormLabel>
                          <Input
                            fontSize="sm"
                            ms="4px"
                            type="text"
                            placeholder="Name(Vietnamese)"
                            size="md"
                            name="name_vi"
                            {...register("name_vi")}
                            isInvalid={errors.name_vi}
                            errorBorderColor="red.300"
                            isDisabled={!isCreate}
                          />
                          <Text color="red" fontSize={14} mb="10px" ml="5px">
                            {errors.name_vi?.message}
                          </Text>
                        </FormControl>
                      </Flex>
                    </Grid>
                    <Grid gap="16px" templateColumns="repeat(2, 1fr)" mb={3}>
                      <Flex direction="column">
                        <FormControl>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Voucher Type
                          </FormLabel>
                          <Controller
                            name="voucher_type"
                            control={control}
                            render={({ field }) => (
                              <ReactSelect
                                {...field}
                                focusBorderColor="blue.500"
                                chakraStyles={chakraStyles}
                                options={listVoucherType}
                                placeholder="Select Voucher Type"
                                getOptionLabel={(i) => i.name}
                                getOptionValue={(option) => option.id}
                                size="sm"
                                closeMenuOnSelect={true}
                                isDisabled={!isCreate}
                              />
                            )}
                          />
                        </FormControl>
                      </Flex>
                      <Flex direction="column">
                        <FormControl>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Tool Type
                          </FormLabel>
                          <Controller
                            name="toolType"
                            control={control}
                            render={({ field }) => (
                              <ReactSelect
                                {...field}
                                focusBorderColor="blue.500"
                                chakraStyles={chakraStyles}
                                options={listToolTypes}
                                placeholder="Select Tool Type"
                                getOptionLabel={(i) => i.name}
                                getOptionValue={(option) => option.id}
                                size="sm"
                                closeMenuOnSelect={true}
                                isDisabled={!isCreate}
                              />
                            )}
                          />
                          <Text color="red" fontSize={14} mb="10px" ml="5px">
                            {errors.toolType?.message}
                          </Text>
                        </FormControl>
                      </Flex>
                    </Grid>
                    <Grid gap="16px" templateColumns="repeat(2, 1fr)" mb={3}>
                      {voucher_type_choose?.id === "2" && (
                        <Flex direction="column">
                          <FormControl>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="normal"
                            >
                              Customer Name
                            </FormLabel>
                            <Input
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Customer Name"
                              size="md"
                              name="customer_name"
                              {...register("customer_name")}
                              isInvalid={errors.customer_name}
                              errorBorderColor="red.300"
                              isDisabled={!isCreate}
                            />
                            <Text color="red" fontSize={14} mb="10px" ml="5px">
                              {errors.customer_name?.message}
                            </Text>
                          </FormControl>
                        </Flex>
                      )}
                    </Grid>
                    {voucher_type_choose?.id === "2" && (
                      <Grid gap="16px" templateColumns="repeat(2, 1fr)" mb={3}>
                        <Flex direction="column">
                          <FormControl>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="normal"
                            >
                              Payment Method
                            </FormLabel>
                            <Controller
                              name="payment_method"
                              control={control}
                              render={({ field }) => (
                                <ReactSelect
                                  {...field}
                                  focusBorderColor="blue.500"
                                  chakraStyles={chakraStyles}
                                  options={listPaymentMethod}
                                  placeholder="Select Payment Method"
                                  getOptionLabel={(i) => i.name}
                                  getOptionValue={(option) => option.id}
                                  size="sm"
                                  isDisabled={!isCreate}
                                />
                              )}
                            />
                            <Text color="red" fontSize={14} mb="10px" ml="5px">
                              {errors.payment_method?.message}
                            </Text>
                          </FormControl>
                        </Flex>
                        <Flex direction="column">
                          <FormControl>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="normal"
                            >
                              Payment Status
                            </FormLabel>
                            <Controller
                              name="payment_status"
                              control={control}
                              render={({ field }) => (
                                <ReactSelect
                                  {...field}
                                  focusBorderColor="blue.500"
                                  chakraStyles={chakraStyles}
                                  options={listPaymentStatus}
                                  placeholder="Select Payment Status"
                                  getOptionLabel={(i) => i.name}
                                  getOptionValue={(option) => option.id}
                                  size="sm"
                                  isDisabled={!isCreate}
                                />
                              )}
                            />
                            <Text color="red" fontSize={14} mb="10px" ml="5px">
                              {errors.payment_status?.message}
                            </Text>
                          </FormControl>
                        </Flex>
                      </Grid>
                    )}
                    <Grid gap="16px" templateColumns="repeat(2, 1fr)" mb={3}>
                      <Flex direction="column">
                        <FormControl>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Channel
                          </FormLabel>
                          <Controller
                            name="channel"
                            control={control}
                            render={({ field }) => (
                              <ReactSelect
                                {...field}
                                focusBorderColor="blue.500"
                                chakraStyles={chakraStyles}
                                options={listChannel}
                                placeholder="Select Channel"
                                getOptionLabel={(i) => i.name}
                                getOptionValue={(option) => option.id}
                                size="sm"
                                isDisabled={!isCreate}
                              />
                            )}
                          />
                          <Text color="red" fontSize={14} mb="10px" ml="5px">
                            {errors.channel?.message}
                          </Text>
                        </FormControl>
                      </Flex>
                      {channel && (
                        <Flex direction="column">
                          <FormControl>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="normal"
                            >
                              Unit
                            </FormLabel>
                            <Controller
                              name="unit_id"
                              control={control}
                              render={({ field }) => (
                                <ReactSelect
                                  {...field}
                                  focusBorderColor="blue.500"
                                  chakraStyles={chakraStyles}
                                  options={listUnit}
                                  placeholder="Select Unit"
                                  getOptionLabel={(i) => i.name}
                                  getOptionValue={(option) => option.id}
                                  size="sm"
                                  isDisabled={!isCreate}
                                />
                              )}
                            />
                            <Text color="red" fontSize={14} mb="10px" ml="5px">
                              {errors.unit_id?.message}
                            </Text>
                          </FormControl>
                        </Flex>
                      )}
                      <Flex direction="column">
                        <FormControl>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Template Voucher
                          </FormLabel>
                          <Controller
                            name="voucher_template_id"
                            control={control}
                            render={({ field }) => (
                              <ReactSelect
                                {...field}
                                focusBorderColor="blue.500"
                                chakraStyles={chakraStyles}
                                options={listVoucherTemplate}
                                placeholder="Select Template Voucher"
                                getOptionLabel={(i) => i.name}
                                getOptionValue={(option) => option.id}
                                size="sm"
                                isDisabled={!isCreate}
                              />
                            )}
                          />
                          <Text color="red" fontSize={14} mb="10px" ml="5px">
                            {errors.unit_id?.message}
                          </Text>
                        </FormControl>
                      </Flex>
                    </Grid>

                    {voucherTemplate &&
                      listShowForEdit &&
                      listShowForEdit.map((item) => (
                        <ListShow
                          key={item.id}
                          register={register}
                          control={control}
                          item={item}
                          listShowChoose={listShowChoose}
                          isCheck={true}
                          disabledAndChecked
                          listVenue={item.venue}
                          listCheckedVenue={item.displayVenue}
                          voucher={voucher}
                          zoneDisplay={listZoneForEdit}
                        />
                      ))}
                    <Grid gap="16px" templateColumns="repeat(3, 1fr)" mb={3}>
                      <Flex direction="column">
                        <FormControl>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Quantity
                          </FormLabel>
                          <Input
                            fontSize="sm"
                            ms="4px"
                            type="number"
                            placeholder="Quantity"
                            size="sm"
                            name="quantity"
                            {...register("quantity")}
                            isInvalid={errors.quantity}
                            errorBorderColor="red.300"
                            onKeyDown={(e) => {
                              if (e.key === "e") {
                                e.preventDefault();
                              }
                            }}
                            isDisabled={!isCreate}
                          />
                          <Text color="red" fontSize={14} mb="10px" ml="5px">
                            {errors.quantity?.message}
                          </Text>
                        </FormControl>
                      </Flex>
                      <Flex direction="column">
                        <FormControl>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Min Buy
                          </FormLabel>
                          <Input
                            fontSize="sm"
                            ms="4px"
                            type="number"
                            placeholder="Min Buy"
                            size="sm"
                            name="min"
                            {...register("min_los")}
                            isInvalid={errors.min_los}
                            errorBorderColor="red.300"
                            onKeyDown={(e) => {
                              if (e.key === "e") {
                                e.preventDefault();
                              }
                            }}
                            isDisabled={!isCreate}
                          />
                          <Text color="red" fontSize={14} mb="10px" ml="5px">
                            {errors.min_los?.message}
                          </Text>
                        </FormControl>
                      </Flex>
                      <Flex direction="column">
                        <FormControl>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Max Buy
                          </FormLabel>
                          <Input
                            fontSize="sm"
                            ms="4px"
                            type="number"
                            placeholder="Max Buy"
                            size="sm"
                            name="max_los"
                            {...register("max_los")}
                            isInvalid={errors.max_los}
                            errorBorderColor="red.300"
                            onKeyDown={(e) => {
                              if (e.key === "e") {
                                e.preventDefault();
                              }
                            }}
                            isDisabled={!isCreate}
                          />
                          <Text color="red" fontSize={14} mb="10px" ml="5px">
                            {errors.max_los?.message}
                          </Text>
                        </FormControl>
                      </Flex>
                    </Grid>
                    {voucherTemplate && voucher_type_choose?.id === "2" && (
                      <>
                        <Text mb={2}>
                          Sale Price:{" "}
                          <span style={{ fontSize: 18, fontWeight: "bold" }}>
                            {voucherTemplate.amount.toLocaleString("en-US")} VNĐ
                          </span>
                        </Text>
                        { }
                        <Text mb={2}>
                          Total Amount:{" "}
                          <span style={{ fontSize: 18, fontWeight: "bold" }}>
                            {(
                              parseInt(voucherTemplate.amount) *
                              parseInt(watch("quantity") || 0)
                            ).toLocaleString("en-US")}{" "}
                            VNĐ
                          </span>
                        </Text>
                      </>
                    )}
                    <Grid gap="16px" templateColumns="repeat(2, 1fr)" mb={3}>
                      <Flex direction="column">
                        <FormControl>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Start Date
                          </FormLabel>
                          <Controller
                            name="from_date"
                            control={control}
                            render={({ field }) => (
                              <DatePickerInput
                                onChange={(value) => field.onChange(value)}
                                disabled={!isCreate}
                                showTimeSelect
                                selected={watch("from_date")}
                                startDate={watch("from_date")}
                                selectsStart
                                endDate={watch("to_date")}
                              />
                            )}
                          />
                          <Text color="red" fontSize={14} mb="10px" ml="5px">
                            {errors.from_date?.message}
                          </Text>
                        </FormControl>
                      </Flex>
                      <Flex direction="column">
                        <FormControl>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            End Date
                          </FormLabel>
                          <Controller
                            name="to_date"
                            control={control}
                            render={({ field }) => (
                              <DatePickerInput
                                onChange={(value) => field.onChange(value)}
                                selected={watch("to_date")}
                                disabled={!isCreate}
                                showTimeSelect
                                selectsEnd
                                startDate={watch("from_date")}
                                endDate={watch("to_date")}
                                minDate={watch("from_date")}
                              />
                            )}
                          />
                          <Text color="red" fontSize={14} mb="10px" ml="5px">
                            {errors.to_date?.message}
                          </Text>
                        </FormControl>
                      </Flex>
                    </Grid>
                    <Grid gap="16px" templateColumns="repeat(2, 1fr)" mb={3}>
                      <Flex direction="column">
                        <FormControl>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Show Date From
                          </FormLabel>
                          <Controller
                            name="from_showdate"
                            control={control}
                            render={({ field }) => (
                              <DatePickerInput
                                onChange={(value) => field.onChange(value)}
                                disabled={!isCreate}
                                mask="99/99/9999"
                                selected={watch("from_showdate")}
                                startDate={watch("from_showdate")}
                                selectsStart
                                endDate={watch("to_showdate")}
                              />
                            )}
                          />
                          <Text color="red" fontSize={14} mb="10px" ml="5px">
                            {errors.from_showdate?.message}
                          </Text>
                        </FormControl>
                      </Flex>
                      <Flex direction="column">
                        <FormControl>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Show Date To
                          </FormLabel>
                          <Controller
                            name="to_showdate"
                            control={control}
                            render={({ field }) => (
                              <DatePickerInput
                                onChange={(value) => field.onChange(value)}
                                selected={field.value}
                                disabled={!isCreate}
                                mask="99/99/9999"
                                selectsEnd
                                startDate={watch("from_showdate")}
                                endDate={watch("to_showdate")}
                                minDate={watch("from_showdate")}
                              />
                            )}
                          />
                          <Text color="red" fontSize={14} mb="10px" ml="5px">
                            {errors.to_showdate?.message}
                          </Text>
                        </FormControl>
                      </Flex>
                    </Grid>
                    <Flex mb="15px" direction="column">
                      <Switch
                        isChecked={exclude}
                        size="sm"
                        ms="4px"
                        mt="20px"
                        disabled={!isCreate}
                        defaultChecked={false}
                        name="exclude"
                        {...register("exclude")}
                      >
                        Exclude show time
                      </Switch>

                      {exclude && (
                        <Flex mt="5px" ml="15px" gap={30}>
                          {Object.entries(groupedShowData).map(
                            ([key, value], index) => (
                              <Flex direction="column">
                                <Flex key={index}>
                                  <Checkbox
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        checkedAllShowTime(value, key);
                                      } else {
                                        removeShowTime(value, key);
                                      }
                                    }}
                                    size="md"
                                    disabled={!isCreate}
                                    isChecked={timeCheckedAll.includes(key)}
                                  />
                                  <Text ml={2}>{key}</Text>
                                </Flex>
                                <Controller
                                  name="excludes"
                                  control={control}
                                  render={({ field }) => (
                                    <CheckboxGroup {...field}>
                                      {value.map((i, index) => (
                                        <Flex key={index}>
                                          <Checkbox
                                            value={i.id.toString()}
                                            size="md"
                                            disabled={!isCreate}
                                          />
                                          <Text ml="10px">
                                            {i.show.name} -{" "}
                                            {moment(i.showdate).format(
                                              "DD/MM/YYYY"
                                            )}
                                          </Text>
                                        </Flex>
                                      ))}
                                    </CheckboxGroup>
                                  )}
                                />
                              </Flex>
                            )
                          )}
                        </Flex>
                      )}
                    </Flex>

                    <Flex direction="column">
                      <FormControl>
                        <Switch
                          disabled={!isCreate}
                          id="isChecked"
                          defaultChecked={true}
                          name="status"
                          {...register("status")}
                          size="sm"
                          ms="4px"
                        >
                          Active
                        </Switch>
                      </FormControl>
                    </Flex>
                    <Text color="red" ml="5px">
                      {errors.apiError && errors.apiError?.message}
                    </Text>
                  </ModalBody>
                  {isCreate && (
                    <ModalFooter pt="0">
                      <Button
                        colorScheme="blue"
                        mr={3}
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Create
                      </Button>
                      <Button
                        onClick={() => {
                          onClose();
                          reset();
                          setListVoucherType([
                            { id: "1", name: "Issue Voucher" },
                            { id: "2", name: "Sell Voucher" },
                          ]);
                        }}
                      >
                        Cancel
                      </Button>
                    </ModalFooter>
                  )}
                </ModalContent>
              </form>
            </Modal>
          </Flex>
        </CardHeader>
        <CardBody>
          <Card>
            <form
              id="issue-voucher-search-form"
              onSubmit={handleSubmitSearch(onSubmitSearch)}
            >
              <Flex
                direction="row"
                alignItems="center"
                mb={3}
                gap={4}
                flexWrap={"wrap"}
              >
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel ms="4px" fontSize="xs" fontWeight="semibold">
                    Voucher Name
                  </FormLabel>
                  <Input
                    _placeholder={{ color: "gray.400" }}
                    variant="main"
                    type="text"
                    placeholder="Voucher Name"
                    minW="80px"
                    width="200px"
                    size="sm"
                    fontSize="sm"
                    id="nameSearch"
                    name="nameSearch"
                    {...registerSearch("nameSearch")}
                    _focus={{ borderColor: "blue.500" }}
                  />
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                    Voucher Type
                  </FormLabel>
                  <Controller
                    name="voucherTypeSearch"
                    control={controlSearch}
                    render={({ field }) => (
                      <ReactSelect
                        {...field}
                        focusBorderColor="blue.500"
                        chakraStyles={chakraStyles}
                        options={listVoucherTypeSearch}
                        placeholder="Select Voucher Type"
                        getOptionLabel={(i) => i.name}
                        getOptionValue={(option) => option.id}
                        size="sm"
                        closeMenuOnSelect={true}
                        isDisabled={!isCreate}
                      />
                    )}
                  />
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                    Tool Type
                  </FormLabel>
                  <Select
                    ms="4px"
                    type="text"
                    defaultValue="0"
                    size="sm"
                    name="toolTypeSearch"
                    {...registerSearch("toolTypeSearch")}
                    width="200px"
                  >
                    <option value="">All Tool Type</option>
                    {listToolTypes.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                    Show
                  </FormLabel>
                  <Select
                    ms="4px"
                    type="text"
                    defaultValue="0"
                    size="sm"
                    name="showSearch"
                    {...registerSearch("showSearch")}
                    width="200px"
                  >
                    <option value="">All Show</option>
                    {listShow.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                    Venue
                  </FormLabel>
                  <Select
                    ms="4px"
                    type="text"
                    size="sm"
                    id={"venueSearch"}
                    name="venueSearch"
                    {...registerSearch("venueSearch")}
                  >
                    <option value="">All Venue</option>
                    {venueList.map((i) => (
                      <option key={i.id} value={i.id}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </Box>

                <Box direction="row" alignItems="center" display="block">
                  <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                    Channel
                  </FormLabel>
                  <Select
                    ms="4px"
                    type="text"
                    size="sm"
                    name="channelSearch"
                    {...registerSearch("channelSearch")}
                    width="200px"
                  >
                    <option value="">All</option>
                    {listChannel.map((i) => (
                      <option key={i.id} value={i.id}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </Box>
                {channelSearch && (
                  <Box direction="row" alignItems="center" display="block">
                    <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                      Unit
                    </FormLabel>
                    <Controller
                      name="unitSearch"
                      control={controlSearch}
                      render={({ field }) => (
                        <ReactSelect
                          {...field}
                          focusBorderColor="blue.500"
                          chakraStyles={chakraStyles}
                          options={listUnit}
                          placeholder="Select Unit"
                          getOptionLabel={(i) => i.name}
                          getOptionValue={(option) => option.id}
                          size="sm"
                        />
                      )}
                    />
                  </Box>
                )}

                <Box direction="row" alignItems="center" display="block">
                  <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                    Status
                  </FormLabel>
                  <Select
                    ms="4px"
                    type="text"
                    defaultValue="1"
                    size="sm"
                    id="statusSearch"
                    name="statusSearch"
                    width="200px"
                    {...registerSearch("statusSearch")}
                  >
                    {VoucherIssueStatus.map((item, index) => (
                      <option key={index} value={item.Id}>
                        {item.Name}
                      </option>
                    ))}
                  </Select>
                </Box>
              </Flex>
              <Flex justify="center" align="center" gap="8px" mt={3}>
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<RxReset />}
                    variant="primary"
                    w="88px"
                    h="33px"
                    onClick={() => {
                      resetSearch();
                      setValueSearch("unitSearch", null);
                    }}
                  >
                    Reset
                  </Button>
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<RiFileExcel2Fill />}
                    variant="primary"
                    w="100px"
                    h="33px"
                    onClick={() => {
                      setIsExportExcel(true);
                    }}
                  >
                    Export Excel
                  </Button>
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<BsSearch />}
                    variant="primary"
                    w="88px"
                    h="33px"
                    type="submit"
                  >
                    Search
                  </Button>
                </Box>
              </Flex>
            </form>
          </Card>
          <SearchTable2
            tableData={newVouchers}
            columnsData={columnsDataVoucher}
            totalRow={totalRows.vouchers}
            showDetail={showDetail}
            showCode={(val) => {
              history.push(`/admin/management/voucher/voucher-detail/${val}`);
            }}
            actionsActive={["showCode", "detail"]}
            listPermissions={[29, 35]}
            isFetching={isFetching}
            loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
            refreshPagingDefault={refreshPagingDefault}
          />
        </CardBody>
      </Card>
    </Flex>
  );
}

export default DataTableIssueVoucher;
