import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Stack,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { columnsDataCurrency } from "variables/columnsData";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { totalRow } from "state/roles-permissions";
import { useBookingActions } from "hooks/useBookingActions";
import { currencyListAtom } from "state/show";
import SearchTable2 from "components/Tables/SearchTable2";
import { NumericFormat } from "react-number-format";

function DataTableCurrency() {
  const textColor = useColorModeValue("gray.700", "white");
  const listCurrency = useRecoilValue(currencyListAtom);
  const totalRows = useRecoilValue(totalRow);
  const bookingActions = useBookingActions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentCurrencyID, setCurrentCurrencyID] = useState();
  const [isFetching, setIsFetching] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    value: Yup.string().required("This field is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    control,
  } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const toast = useToast();
  const recallApi = async () => {
    setIsFetching(true);
    await bookingActions.getListCurrency();
    setIsFetching(false);
  };
  async function onSubmit({ name, value }) {
    const params = {
      name,
      value: value.replace(/^0+|,/g, ""),
    };
    try {
      let res = await bookingActions.updateCurrency(params, currentCurrencyID);

      if (res.status === 200) {
        onClose();
        reset();
        const message = "Update Currency success";
        toast({
          description: message,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        await bookingActions.getListCurrency();
      }
    } catch (err) {
      console.warn(err);
      toast({
        description: err.response.data.meta.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  async function editCurrency(currencyId) {
    const currency = await bookingActions.getCurrencyDetail(currencyId);
    if (currency) {
      const { key, value, id } = currency.data.data;
      setValue("name", key);
      setValue("value", value);
      setCurrentCurrencyID(id);
    }
    onOpen();
  }

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="12px">
          <Flex direction="column" display="block">
            <Text
              float="left"
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              mb="6px"
            >
              Currency Management
            </Text>
            <Stack float="right" direction="row" spacing={4}></Stack>
            <Modal
              closeOnOverlayClick={false}
              isOpen={isOpen}
              onClose={() => {
                onClose();
                reset();
              }}
              size="2xl"
            >
              <ModalOverlay />
              <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                  <ModalHeader>Edit Currency</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <FormControl isRequired>
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Name
                      </FormLabel>
                      <Input
                        fontSize="sm"
                        ms="4px"
                        type="text"
                        placeholder="Name"
                        maxLength={250}
                        mb={
                          errors.name?.message ? "5px" : { lg: "24px", sm: "0" }
                        }
                        size="md"
                        name="name"
                        {...register("name")}
                        isInvalid={errors.name}
                        errorBorderColor="red.300"
                      />
                      <Text color="red" fontSize={14} mb="10px" ml="5px">
                        {errors.name?.message}
                      </Text>
                    </FormControl>
                    <FormControl>
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Amount
                      </FormLabel>
                      <Controller
                        control={control}
                        name="value"
                        render={({ field }) => (
                          <NumericFormat
                            customInput={Input}
                            thousandSeparator=","
                            fontSize="sm"
                            ms="4px"
                            placeholder="Amount"
                            size="sm"
                            {...field}
                          />
                        )}
                      />

                      <Text color="red" ml="5px">
                        {errors.apiError && errors.apiError?.message}
                      </Text>
                    </FormControl>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      colorScheme="blue"
                      mr={3}
                      type="submit"
                      isLoading={isSubmitting}
                    >
                      Update
                    </Button>
                    <Button
                      onClick={() => {
                        onClose();
                        reset();
                      }}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </form>
            </Modal>
          </Flex>
        </CardHeader>
        <CardBody>
          <SearchTable2
            tableData={listCurrency}
            columnsData={columnsDataCurrency}
            totalRow={totalRows.currencies}
            edit={editCurrency}
            actionsActive={["edit"]}
            listPermissions={[57]}
            loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
            isFetching={isFetching}
          />
        </CardBody>
      </Card>
    </Flex>
  );
}

export default DataTableCurrency;
