import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Switch,
  Stack,
  Box,
  Select,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import SearchTable2 from "components/Tables/SearchTable2";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { columnsDataLocation } from "variables/columnsData";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { totalRow } from "state/roles-permissions";
import { AddIcon } from "@chakra-ui/icons";
import { checkForbidden } from "helpers/checkForbidden";
import { useBookingActions } from "hooks/useBookingActions";
import { locationListAtom } from "state/show";
import { STATUS } from "variables/general";
import { BsSearch } from "react-icons/bs";
import { RxReset } from "react-icons/rx";
function DataTableLocation() {
  const textColor = useColorModeValue("gray.700", "white");
  const listLocation = useRecoilValue(locationListAtom);
  const totalRows = useRecoilValue(totalRow);
  const bookingActions = useBookingActions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCreate, setIsCreate] = useState(true);
  const [currentLocationID, setCurrentLocationID] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [newLocations, setNewLocations] = useState([]);
  const [refreshPagingDefault, setRefreshPagingDefault] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, reset, setValue } = useForm(
    formOptions
  );
  const {
    register: registerSearch,
    handleSubmit: handleSubmitSearch,
    watch: watchSearch,
    reset: resetSearch,
  } = useForm();
  const { errors, isSubmitting } = formState;
  const toast = useToast();
  const statusSearch = watchSearch("statusSearch");
  const nameSearch = watchSearch("nameSearch");

  useEffect(() => {
    if (listLocation) {
      const newListLocation = listLocation.map((i) => ({
        ...i,
        typeSwitch: "location",
        filters: {
          name: nameSearch,
          status: statusSearch ? statusSearch : 1,
        },
      }));
      setNewLocations(newListLocation);
    }
  }, [listLocation]);
  const recallApi = async (fetchDataOptions) => {
    setIsFetching(true);
    await bookingActions.getListLocations(
      {
        status: statusSearch ? statusSearch : 1,
        name: nameSearch,
        sort_type: fetchDataOptions.sort_type,
        sort_field: fetchDataOptions.sort_field,
      },
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
    setIsFetching(false);
  };
  async function onSubmit({ name, isActive }) {
    const params = {
      name,
      status: isActive ? 1 : 2,
    };
    try {
      let res;
      if (isCreate) {
        res = await bookingActions.createLocation(params);
      } else {
        res = await bookingActions.updateLocation(params, currentLocationID);
      }

      if (res.status === 200) {
        onClose();
        reset();
        const message = isCreate
          ? "Create Location success"
          : "Update Location success";
        toast({
          description: message,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        await bookingActions.getListLocations(
          {
            status: statusSearch ? statusSearch : 1,
            name: nameSearch,
          },
          0,
          localStorage && localStorage.getItem("currentPageSizeStore")
        );
      }
    } catch (err) {
      console.warn(err);
      toast({
        description: err?.response?.data?.meta?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }
  async function onSubmitSearch({ nameSearch, statusSearch }) {
    setIsFetching(true);
    await bookingActions.getListLocations(
      {
        name: nameSearch,
        status: statusSearch,
      },
      0,
      localStorage && localStorage.getItem("currentPageSizeStore")
        ? localStorage.getItem("currentPageSizeStore")
        : 10
    );
    setRefreshPagingDefault(true)
    setIsFetching(false);
  }
  async function editLocation(locationID) {
    const roleDetails = await bookingActions.getLocationDetail(locationID);
    if (roleDetails) {
      const { name, id, status } = roleDetails.data.data;
      setValue("name", name);
      setValue("isActive", status === 1 ? true : false);
      setCurrentLocationID(id);
    }
    setIsCreate(false);
    onOpen();
  }

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="12px">
          <Flex direction="column" display="block">
            <Text
              float="left"
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              mb="6px"
            >
              Location Management
            </Text>
            <Stack float="right" direction="row" spacing={4}>
              {checkForbidden(66) && (
                <Button
                  size="sm"
                  onClick={() => {
                    onOpen();
                    setIsCreate(true);
                  }}
                  leftIcon={<AddIcon />}
                  colorScheme="green"
                  variant="outline"
                >
                  Add Location
                </Button>
              )}
            </Stack>
            <Modal
              closeOnOverlayClick={false}
              isOpen={isOpen}
              onClose={() => {
                onClose();
                reset();
              }}
              size="2xl"
            >
              <ModalOverlay />
              <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                  <ModalHeader>
                    {isCreate ? "Add New Location" : "Edit Location"}
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <FormControl isRequired>
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Name
                      </FormLabel>
                      <Input
                        fontSize="sm"
                        ms="4px"
                        type="text"
                        placeholder="Name"
                        maxLength={250}
                        mb={
                          errors.name?.message ? "5px" : { lg: "24px", sm: "0" }
                        }
                        size="md"
                        name="name"
                        {...register("name")}
                        isInvalid={errors.name}
                        errorBorderColor="red.300"
                      />
                      <Text color="red" fontSize={14} mb="10px" ml="5px">
                        {errors.name?.message}
                      </Text>
                    </FormControl>
                    <FormControl>
                      <Switch
                        id="isChecked"
                        defaultChecked={isCreate && true}
                        name="isActive"
                        {...register("isActive")}
                        size="sm"
                        ms="4px"
                      >
                        Active
                      </Switch>
                    </FormControl>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      colorScheme="blue"
                      mr={3}
                      type="submit"
                      isLoading={isSubmitting}
                    >
                      {isCreate ? "Create" : "Update"}
                    </Button>
                    <Button
                      onClick={() => {
                        onClose();
                        reset();
                      }}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </form>
            </Modal>
          </Flex>
        </CardHeader>
        <CardBody>
          <Card>
            <form
              id="user-search-form"
              onSubmit={handleSubmitSearch(onSubmitSearch)}
            >
              <Flex
                direction="row"
                alignItems="center"
                mb={3}
                gap={4}
                flexWrap={"wrap"}
              >
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel ms="4px" fontSize="xs" fontWeight="semibold">
                    Location Name
                  </FormLabel>
                  <Input
                    _placeholder={{ color: "gray.400" }}
                    variant="main"
                    type="text"
                    placeholder="Name"
                    minW="80px"
                    maxW="185px"
                    size="sm"
                    fontSize="sm"
                    name="nameSearch"
                    {...registerSearch("nameSearch")}
                    _focus={{ borderColor: "blue.500" }}
                  />
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                    Status
                  </FormLabel>
                  <Select
                    ms="4px"
                    type="text"
                    defaultValue="1"
                    size="sm"
                    name="statusSearch"
                    {...registerSearch("statusSearch")}
                    width="100px"
                  >
                    {STATUS.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </Box>
              </Flex>
              <Flex justify="center" align="center" gap="8px" mt={3}>
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<RxReset />}
                    variant="primary"
                    w="88px"
                    h="33px"
                    onClick={() => {
                      resetSearch();
                    }}
                  >
                    Reset
                  </Button>
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<BsSearch />}
                    variant="primary"
                    w="88px"
                    h="33px"
                    type="submit"
                  >
                    Search
                  </Button>
                </Box>
              </Flex>
            </form>
          </Card>
          <SearchTable2
            tableData={newLocations}
            columnsData={columnsDataLocation}
            totalRow={totalRows.locations}
            edit={editLocation}
            actionsActive={["edit"]}
            listPermissions={[67]}
            isFetching={isFetching}
            loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
            refreshPagingDefault={refreshPagingDefault}
          />
        </CardBody>
      </Card>
    </Flex>
  );
}

export default DataTableLocation;
