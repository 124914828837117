import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Switch,
  Stack,
  Box,
  Select,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import SearchTable2 from "components/Tables/SearchTable2";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { columnsDataSeatingPlan } from "variables/columnsData";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { totalRow } from "state/roles-permissions";
import { AddIcon } from "@chakra-ui/icons";
import { checkForbidden } from "helpers/checkForbidden";
import { useBookingActions } from "hooks/useBookingActions";
import { seatingPlanListAtom, venueListAtom, listShowAtom } from "state/show";
import { BsSearch } from "react-icons/bs";
import { RxReset } from "react-icons/rx";
import { STATUS } from "variables/general";
import { Select as ReactSelect } from "chakra-react-select";
import { useHistory } from "react-router-dom";
import { useRatePlanActions } from "hooks/useRatePlanActions";

function DataTableSeatingPlan() {
  const chakraStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      p: 0,
      w: "20px",
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: "200px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 20,
    }),
  };

  const history = useHistory();
  const textColor = useColorModeValue("gray.700", "white");
  const listSeatingPlan = useRecoilValue(seatingPlanListAtom);
  const listVenue = useRecoilValue(venueListAtom);
  const listShow = useRecoilValue(listShowAtom);
  const totalRows = useRecoilValue(totalRow);
  const bookingActions = useBookingActions();
  const ratePlanActions = useRatePlanActions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isFetching, setIsFetching] = useState(false);
  const [newSeatingPlans, setNewSeatingPlans] = useState([]);
  const [refreshPagingDefault, setRefreshPagingDefault] = useState(false);
  const [images, setImages] = useState({
    desktopMaps: "",
    mobileMaps: "",
    legendDesktopMaps: "",
    legendMobileMaps: "",
    seats: "",
  });
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    venue: Yup.object().required("This field is required"),
    show: Yup.object().required("This field is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    control,
  } = useForm(formOptions);
  const {
    register: registerSearch,
    handleSubmit: handleSubmitSearch,
    watch: watchSearch,
    reset: resetSearch,
  } = useForm();
  const { errors, isSubmitting } = formState;
  const toast = useToast();
  const statusSearch = watchSearch("statusSearch");
  const nameSearch = watchSearch("nameSearch");
  const showSearch = watchSearch("show_id");
  const venueSearch = watchSearch("venue");
  useEffect(() => {
    bookingActions.getListVenue({ status: 1 }, 0, "");
    bookingActions.getListShow({ status: 1 }, 0, "");
  }, []);
  useEffect(() => {
    if (listSeatingPlan) {
      const newListSeatingPlan = listSeatingPlan.map((i) => ({
        ...i,
        typeSwitch: "seatingPlan",
        filters: {
          name: nameSearch,
          show_id: showSearch,
          venue_id: venueSearch,
          status: statusSearch,
        },
      }));
      setNewSeatingPlans(newListSeatingPlan);
    }
  }, [listSeatingPlan]);
  async function onSubmitSearch({
    nameSearch,
    statusSearch,
    show_id,
    venue_id,
  }) {
    setIsFetching(true);
    await bookingActions.getListSeatingPlan(
      { name: nameSearch, status: statusSearch, show_id, venue_id },
      0,
      localStorage && localStorage.getItem("currentPageSizeStore")
        ? localStorage.getItem("currentPageSizeStore")
        : 10
    );
    setRefreshPagingDefault(true)
    setIsFetching(false);
  }
  const recallApi = async (fetchDataOptions) => {
    setIsFetching(true);
    await bookingActions.getListSeatingPlan(
      {
        name: nameSearch,
        show_id: showSearch,
        venue_id: venueSearch,
        status: statusSearch ? statusSearch : 1,
        sort_type: fetchDataOptions.sort_type,
        sort_field: fetchDataOptions.sort_field,
      },
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
    setIsFetching(false);
  };
  const changeImage = async (val, type) => {
    const formData = new FormData();
    formData.append("file", val.target.files[0]);
    const {
      data: { data },
    } = await ratePlanActions.uploadImageRatePlanConfig(formData);
    const url = data.url;
    switch (type) {
      case 1:
        setImages((preS) => ({ ...preS, desktopMaps: url }));
        break;
      case 2:
        setImages((preS) => ({ ...preS, mobileMaps: url }));
        break;
      case 3:
        setImages((preS) => ({ ...preS, legendDesktopMaps: url }));
        break;
      case 4:
        setImages((preS) => ({ ...preS, legendMobileMaps: url }));
        break;
      case 5:
        setImages((preS) => ({ ...preS, seats: url }));
        break;
      default:
        break;
    }
  };
  async function onSubmit({ name, isActive, show, venue, seats }) {
    const params = {
      name,
      venue_id: venue.id,
      show_id: show.id,
      desktop_maps: images.desktopMaps,
      mobile_maps: images.mobileMaps,
      legend_desktop_maps: images.legendDesktopMaps,
      legend_mobile_maps: images.legendMobileMaps,
      seats: images.seats,
      status: isActive ? 1 : 2,
    };
    try {
      const res = await bookingActions.createSeatingPlan(params);
      if (res.status === 200) {
        onClose();
        reset();
        const message = "Create Seating Plan success";
        toast({
          description: message,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        await bookingActions.getListSeatingPlan(
          {
            name: nameSearch,
            show_id: showSearch,
            venue_id: venueSearch,
            status: statusSearch ? statusSearch : 1,
          },
          0,
          localStorage && localStorage.getItem("currentPageSizeStore")
            ? localStorage.getItem("currentPageSizeStore")
            : 10
        );
      }
    } catch (err) {
      console.warn(err);
      toast({
        description: err?.response?.data?.meta?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="12px">
          <Flex direction="column" display="block">
            <Text
              float="left"
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              mb="6px"
            >
              Seating Plan Management
            </Text>
            <Stack float="right" direction="row" spacing={4}>
              {checkForbidden(42) && (
                <Button
                  size="sm"
                  onClick={() => {
                    onOpen();
                  }}
                  leftIcon={<AddIcon />}
                  colorScheme="green"
                  variant="outline"
                >
                  Add Seating Plan
                </Button>
              )}
            </Stack>
            <Modal
              closeOnOverlayClick={false}
              isOpen={isOpen}
              onClose={() => {
                onClose();
                reset();
              }}
              size="2xl"
            >
              <ModalOverlay />
              <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                  <ModalHeader>Add New Seating Plan</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <FormControl>
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Name
                      </FormLabel>
                      <Input
                        fontSize="sm"
                        ms="4px"
                        type="text"
                        placeholder="Name"
                        maxLength={250}
                        mb={
                          errors.name?.message ? "5px" : { lg: "24px", sm: "0" }
                        }
                        size="md"
                        name="name"
                        {...register("name")}
                        isInvalid={errors.name}
                        errorBorderColor="red.300"
                      />
                      <Text color="red" fontSize={14} ml="5px">
                        {errors.name?.message}
                      </Text>
                    </FormControl>
                    <FormControl mb={{ lg: "24px", sm: "4px" }} isRequired>
                      <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                        Venue
                      </FormLabel>
                      <Controller
                        name="venue"
                        control={control}
                        render={({ field }) => (
                          <ReactSelect
                            {...field}
                            focusBorderColor="blue.500"
                            chakraStyles={chakraStyles}
                            options={listVenue}
                            placeholder="Select Venue"
                            getOptionLabel={(i) => i.name}
                            getOptionValue={(option) => option.id}
                            size="sm"
                          />
                        )}
                      />
                      <Text color="red" fontSize={14} ml="5px">
                        {errors.venue?.message}
                      </Text>
                    </FormControl>
                    <FormControl mb={{ lg: "24px", sm: "4px" }} isRequired>
                      <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                        Show
                      </FormLabel>
                      <Controller
                        name="show"
                        control={control}
                        render={({ field }) => (
                          <ReactSelect
                            {...field}
                            focusBorderColor="blue.500"
                            chakraStyles={chakraStyles}
                            options={listShow}
                            placeholder="Select Show"
                            getOptionLabel={(i) => i.name}
                            getOptionValue={(option) => option.id}
                            size="sm"
                          />
                        )}
                      />
                      <Text color="red" fontSize={14} ml="5px">
                        {errors.show?.message}
                      </Text>
                    </FormControl>
                    <FormControl isRequired mt={4} mb={2}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Import Seating Plan Image
                      </FormLabel>
                      <Input
                        fontSize="sm"
                        ms="4px"
                        type="file"
                        size="sm"
                        onChange={(val) => changeImage(val, 1)}
                      />
                    </FormControl>
                    <FormControl isRequired mt={4} mb={2}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Import Seating Plan Image (Mobile)
                      </FormLabel>
                      <Input
                        fontSize="sm"
                        ms="4px"
                        type="file"
                        size="sm"
                        name="mobile_maps"
                        onChange={(val) => changeImage(val, 2)}
                      />
                    </FormControl>
                    <FormControl isRequired mt={4} mb={2}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Import Legend Desktop
                      </FormLabel>
                      <Input
                        fontSize="sm"
                        ms="4px"
                        type="file"
                        size="sm"
                        onChange={(val) => changeImage(val, 3)}
                      />
                    </FormControl>
                    <FormControl isRequired mt={4} mb={2}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Import Legend Desktop (Mobile)
                      </FormLabel>
                      <Input
                        fontSize="sm"
                        ms="4px"
                        type="file"
                        size="sm"
                        onChange={(val) => changeImage(val, 4)}
                      />
                    </FormControl>
                    <FormControl isRequired mt={4} mb={2}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Seats
                      </FormLabel>
                      <Input
                        fontSize="sm"
                        ms="4px"
                        type="file"
                        size="sm"
                        onChange={(val) => changeImage(val, 5)}
                      />
                    </FormControl>
                    <FormControl>
                      <Switch
                        id="isChecked"
                        defaultChecked={true}
                        name="isActive"
                        {...register("isActive")}
                        size="sm"
                        ms="4px"
                      >
                        Active
                      </Switch>
                    </FormControl>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      colorScheme="blue"
                      mr={3}
                      type="submit"
                      isLoading={isSubmitting}
                    >
                      Create
                    </Button>
                    <Button
                      onClick={() => {
                        onClose();
                        reset();
                      }}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </form>
            </Modal>
          </Flex>
        </CardHeader>
        <CardBody>
          <Card>
            <form
              id="user-search-form"
              onSubmit={handleSubmitSearch(onSubmitSearch)}
            >
              <Flex
                direction="row"
                alignItems="center"
                mb={3}
                gap={4}
                flexWrap={"wrap"}
              >
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel ms="4px" fontSize="xs" fontWeight="semibold">
                    Name
                  </FormLabel>
                  <Input
                    _placeholder={{ color: "gray.400" }}
                    variant="main"
                    type="text"
                    placeholder="Name"
                    minW="80px"
                    maxW="185px"
                    size="sm"
                    fontSize="sm"
                    name="nameSearch"
                    {...registerSearch("nameSearch")}
                    _focus={{ borderColor: "blue.500" }}
                  />
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel ms="4px" fontSize="xs" fontWeight="semibold">
                    Show
                  </FormLabel>
                  <Select
                    ms="4px"
                    type="text"
                    size="sm"
                    name="show_id"
                    {...registerSearch("show_id")}
                    width="200px"
                  >
                    <option value="">All Show</option>
                    {listShow.map((i) => (
                      <option key={i.id} value={i.id}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel ms="4px" fontSize="xs" fontWeight="semibold">
                    Venue
                  </FormLabel>
                  <Select
                    ms="4px"
                    type="text"
                    size="sm"
                    name="venue_id"
                    {...registerSearch("venue_id")}
                    width="200px"
                  >
                    <option value="">All Venue</option>
                    {listVenue.map((i) => (
                      <option key={i.id} value={i.id}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                    Status
                  </FormLabel>
                  <Select
                    ms="4px"
                    type="text"
                    defaultValue="1"
                    size="sm"
                    name="statusSearch"
                    {...registerSearch("statusSearch")}
                    width="100px"
                  >
                    {STATUS.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </Box>
              </Flex>
              <Flex justify="center" align="center" gap="8px" mt={3}>
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<RxReset />}
                    variant="primary"
                    w="88px"
                    h="33px"
                    onClick={() => {
                      resetSearch();
                    }}
                  >
                    Reset
                  </Button>
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<BsSearch />}
                    variant="primary"
                    w="88px"
                    h="33px"
                    type="submit"
                  >
                    Search
                  </Button>
                </Box>
              </Flex>
            </form>
          </Card>
          <SearchTable2
            tableData={newSeatingPlans}
            columnsData={columnsDataSeatingPlan}
            totalRow={totalRows.seatingPlans}
            actionsActive={["manage"]}
            manage={(item) => {
              localStorage.setItem(
                "seatingPlanAssign",
                JSON.stringify({
                  desktop_maps: item.desktop_maps,
                  legend_desktop_maps: item.legend_desktop_maps,
                  venue_id: item.venue_id,
                  show_id: item.show.id,
                  seatingplan_id: item.id,
                })
              );
              history.push(`/admin/setting/seatingplan/assign/${item.id}`);
            }}
            listPermissions={[43]}
            isFetching={isFetching}
            loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
            refreshPagingDefault={refreshPagingDefault}
          />
        </CardBody>
      </Card>
    </Flex>
  );
}

export default DataTableSeatingPlan;
