import axios from "axios";
import dotenv from "dotenv";
dotenv.config();

const instance = axios.create({
  timeout: 30000,
  baseURL: process.env.REACT_APP_API_URL,
});
instance.interceptors.request.use(
  (config) => {
    // perform some action before the request is sent
    return config;
  },
  (error) => {
    // handle the request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    // handle the response data
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      window.location.reload(false);
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
