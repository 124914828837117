import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useUserActions } from "hooks/useUserActions";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { usersAtom } from "state/users";
import { columnsDataUser } from "variables/columnsData";
import { useRolePermissionActions } from "hooks/useRolePermissionActions";
import { rolesAtom, totalRow } from "state/roles-permissions";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Select as ReactSelect } from "chakra-react-select";
import { useHistory } from "react-router-dom";
import { AddIcon } from "@chakra-ui/icons";
import { checkForbidden } from "helpers/checkForbidden";
import { BsSearch } from "react-icons/bs";
import { RxReset } from "react-icons/rx";
import SearchTable2 from "components/Tables/SearchTable2";

function DataTablesUser() {
  const textColor = useColorModeValue("gray.700", "white");
  const userActions = useUserActions();
  const users = useRecoilValue(usersAtom);
  const roleAction = useRolePermissionActions();
  const totalRows = useRecoilValue(totalRow);
  const roles = useRecoilValue(rolesAtom);
  const [isCreate, setIsCreate] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentID, setCurrentID] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [newUser, setNewUser] = useState([]);
  const [refreshPagingDefault, setRefreshPagingDefault] = useState(false);

  const chakraStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      p: 0,
      w: "20px",
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: "200px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 15,
    }),
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("This field is required")
      .email("Invalid Email"),
    fullname: Yup.string().required("This field is required"),
    phone: Yup.string().required("This field is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    setError,
    formState,
    reset,
    setValue,
    control,
  } = useForm(formOptions);
  const {
    register: registerSearch,
    handleSubmit: handleSubmitSearch,
    control: controlSearch,
    reset: resetSearch,
    setValue: setValueSearch,
    watch,
  } = useForm();
  const { errors, isSubmitting } = formState;
  const toast = useToast();
  const fullNameSearch = watch("fullnameSearch");
  const emailSearch = watch("emailSearch");
  const statusSearch = watch("statusSearch");
  const roleSearch = watch("roleSearch");

  const recallApi = async (fetchDataOptions) => {
    setIsFetching(true);

    const roleIDArray = roleSearch?.map((i) => i.id) ?? [];
    const roleIDs = roleIDArray.toString();
    await userActions.getAllUsers(
      {
        role_ids: roleIDs,
        fullname: fullNameSearch,
        email: emailSearch,
        status: statusSearch ? statusSearch : 1,
        sort_type: fetchDataOptions.sort_type,
        sort_field: fetchDataOptions.sort_field,
      },
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
    setIsFetching(false);

  };

  useEffect(() => {
    if (users) {
      const newUsers = users.map((i) => ({
        ...i,
        typeSwitch: "user",
        filters: {
          role_ids: "",
          fullname: fullNameSearch,
          email: emailSearch,
          status: statusSearch,
        },
      }));
      setNewUser(newUsers);
    }
  }, [users]);

  useEffect(() => {
    roleAction.getAllRoles({ name: "", status: 1 }, "", "");
  }, []);

  const history = useHistory();

  function showDetail(val) {
    history.push(`/admin/setting/user-detail/${val}`);
  }

  async function edit(val) {
    await userActions.getUserById(val).then((res) => {
      if (res.status === 200) {
        setValue("email", res.data.data.email);
        setValue("fullname", res.data.data.fullname);
        setValue("phone", res.data.data.phone);
        setValue("roles", res.data.data.role);
        setValue("isActive", res.data.data.status === 1 ? true : false);
        setCurrentID(res.data.data.id);
      }
    });
    setIsCreate(false);
    onOpen();
  }

  async function onSubmitSearch({
    fullnameSearch,
    emailSearch,
    statusSearch,
    roleSearch,
  }) {
    setIsFetching(true);
    const roleIDArray = roleSearch?.map((i) => i.id) ?? [];
    const roleIDs = roleIDArray.toString();
    await userActions.getAllUsers(
      {
        role_ids: roleIDs,
        fullname: fullnameSearch,
        email: emailSearch,
        status: statusSearch,
      },
      0,
      localStorage && localStorage.getItem("currentPageSizeStore")
        ? localStorage.getItem("currentPageSizeStore")
        : 10
    );
    setRefreshPagingDefault(true)
    setIsFetching(false);
  }

  async function onSubmit({ email, fullname, phone, isActive, roles }) {
    try {
      const roleIDs = roles?.map((i) => i.id) ?? [];
      if (isCreate) {
        await userActions.createUser({
          email,
          fullname,
          phone,
          role_ids: roleIDs,
          status: isActive ? 1 : 2,
        });
        toast({
          description: `Create user successful`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      } else {
        await userActions.updateUser(
          {
            email,
            fullname,
            phone,
            role_ids: roleIDs,
            status: isActive ? 1 : 2,
          },
          currentID
        );
        setValue("roles", []);
        toast({
          description: `Edit user successful`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
      reset();
      const currentPageSizeStore =
        localStorage && localStorage.getItem("currentPageSizeStore");
      await userActions.getAllUsers(
        {
          role_ids: roleIDs,
          fullname: fullNameSearch,
          email: emailSearch,
          status: statusSearch,
        },
        0,
        currentPageSizeStore ?? 10
      );
      onClose();
    } catch (err) {
      setError("apiError", {
        message: err.response?.data?.meta?.message ?? err.message,
      });
    }
  }

  const UserStatus = [
    { Id: "0", Name: "All" },
    { Id: "1", Name: "Active" },
    { Id: "2", Name: "InActive" },
  ];

  const resetPassWord = async (id) => {
    userActions
      .resetPassword(id)
      .then((res) => {
        if (res.status === 200) {
          toast({
            description: "Reset Password successful",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        toast({
          description: "Reset Password not successful",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      });
  };

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="12px">
          <Flex direction="column" display="block">
            <Text
              float="left"
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              mb="6px"
            >
              User Management
            </Text>
            <Stack float="right" direction="row" spacing={4}>
              {checkForbidden(38) && (
                <Button
                  size="sm"
                  onClick={() => {
                    onOpen();
                    setIsCreate(true);
                  }}
                  leftIcon={<AddIcon />}
                  colorScheme="green"
                  variant="outline"
                >
                  Add User
                </Button>
              )}
            </Stack>
            <Modal
              closeOnOverlayClick={false}
              isOpen={isOpen}
              onClose={() => {
                onClose();
                reset();
                setValue("roles", []);
              }}
              size="3xl"
            >
              <ModalOverlay />
              <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                  <ModalHeader>
                    {isCreate ? "Create New User" : "Edit User"}
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <Grid
                      gap={{ lg: "16px", sm: "8px" }}
                      templateColumns={{ lg: "repeat(2, 1fr)", sm: "1fr" }}
                    >
                      <Flex direction="column">
                        <FormControl isRequired>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Email
                          </FormLabel>
                          <Input
                            fontSize="sm"
                            ms="4px"
                            type="text"
                            placeholder="Email"
                            mb={
                              errors.email?.message
                                ? "5px"
                                : { lg: "24px", sm: "0" }
                            }
                            size="md"
                            name="email"
                            {...register("email")}
                            isInvalid={errors.email}
                            errorBorderColor="red.300"
                            disabled={!isCreate}
                          />
                          <Text color="red" fontSize={14} mb="10px" ml="5px">
                            {errors.email?.message}
                          </Text>
                        </FormControl>
                      </Flex>
                      <Flex direction="column">
                        <FormControl isRequired>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Full name
                          </FormLabel>
                          <Input
                            fontSize="sm"
                            ms="4px"
                            type="text"
                            placeholder="Full Name"
                            mb={
                              errors.fullname?.message
                                ? "5px"
                                : { lg: "24px", sm: "0" }
                            }
                            size="md"
                            name="fullname"
                            {...register("fullname")}
                            isInvalid={errors.fullname}
                            errorBorderColor="red.300"
                          />
                          <Text color="red" fontSize={14} mb="10px" ml="5px">
                            {errors.fullname?.message}
                          </Text>
                        </FormControl>
                      </Flex>
                    </Grid>
                    <Grid
                      gap={{ lg: "16px", sm: "8px" }}
                      templateColumns={{ lg: "repeat(2, 1fr)", sm: "1fr" }}
                    >
                      <Flex direction="column">
                        <FormControl isRequired>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Phone
                          </FormLabel>
                          <Input
                            fontSize="sm"
                            ms="4px"
                            type="number"
                            placeholder="Phone"
                            mb={
                              errors.phone?.message
                                ? "5px"
                                : { lg: "24px", sm: "0" }
                            }
                            size="md"
                            name="phone"
                            {...register("phone")}
                            isInvalid={errors.phone}
                            errorBorderColor="red.300"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          <Text color="red" fontSize={14} mb="10px" ml="5px">
                            {errors.phone?.message}
                          </Text>
                        </FormControl>
                      </Flex>
                    </Grid>
                    <Grid
                      gap="16px"
                      templateColumns={{ lg: "repeat(2, 1fr)", sm: "1fr" }}
                    >
                      <Flex direction="column">
                        <FormControl isRequired>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Roles
                          </FormLabel>
                          <Controller
                            name="roles"
                            control={control}
                            render={({ field }) => (
                              <ReactSelect
                                {...field}
                                isMulti
                                options={roles}
                                placeholder="Select roles..."
                                size="sm"
                                colorScheme="green"
                                focusBorderColor="blue.500"
                                chakraStyles={chakraStyles}
                                getOptionLabel={(op) => op.name}
                                getOptionValue={(option) => option.id}
                                closeMenuOnSelect={false}
                              />
                            )}
                          />

                          <Text color="red" fontSize={14} mb="10px" ml="5px">
                            {errors.roles?.message}
                          </Text>
                        </FormControl>
                      </Flex>
                    </Grid>
                    <Flex direction="column">
                      <FormControl>
                        <Switch
                          id="isChecked"
                          defaultChecked={isCreate && true}
                          name="isActive"
                          {...register("isActive")}
                          size="sm"
                          ms="4px"
                        >
                          Active
                        </Switch>
                      </FormControl>
                    </Flex>
                    <Text color="red" ml="5px">
                      {errors.apiError && errors.apiError?.message}
                    </Text>
                  </ModalBody>
                  <ModalFooter pt="0">
                    <Button
                      colorScheme="blue"
                      mr={3}
                      type="submit"
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                    >
                      {isCreate ? "Create" : "Update"}
                    </Button>
                    <Button
                      onClick={() => {
                        onClose();
                        reset();
                        setValue("roles", []);
                      }}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </form>
            </Modal>
          </Flex>
        </CardHeader>
        <CardBody>
          <Card>
            <form
              id="user-search-form"
              onSubmit={handleSubmitSearch(onSubmitSearch)}
            >
              <Flex
                direction="row"
                alignItems="center"
                mb={3}
                gap={4}
                flexWrap={"wrap"}
              >
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel ms="4px" fontSize="xs" fontWeight="semibold">
                    Full Name
                  </FormLabel>
                  <Input
                    _placeholder={{ color: "gray.400" }}
                    variant="main"
                    type="text"
                    placeholder="Full Name"
                    minW="80px"
                    maxW="185px"
                    size="sm"
                    fontSize="sm"
                    name="fullnameSearch"
                    {...registerSearch("fullnameSearch")}
                    _focus={{ borderColor: "blue.500" }}
                  />
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel ms="4px" fontSize="xs" fontWeight="semibold">
                    Email
                  </FormLabel>
                  <Input
                    _placeholder={{ color: "gray.400" }}
                    variant="main"
                    type="text"
                    placeholder="Email"
                    minW="80px"
                    maxW="185px"
                    size="sm"
                    fontSize="sm"
                    name="emailSearch"
                    {...registerSearch("emailSearch")}
                    _focus={{ borderColor: "blue.500" }}
                  />
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                    Status
                  </FormLabel>
                  <Select
                    ms="4px"
                    type="text"
                    defaultValue="1"
                    size="sm"
                    name="statusSearch"
                    {...registerSearch("statusSearch")}
                    width="100px"
                  >
                    {UserStatus.map((item, index) => (
                      <option key={index} value={item.Id}>
                        {item.Name}
                      </option>
                    ))}
                  </Select>
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel
                    htmlFor="role-search-lbl"
                    fontWeight="semibold"
                    fontSize="xs"
                    ms="4px"
                  >
                    Role
                  </FormLabel>
                  <Controller
                    name="roleSearch"
                    control={controlSearch}
                    render={({ field }) => (
                      <ReactSelect
                        {...field}
                        isMulti
                        options={roles}
                        placeholder="Select roles..."
                        size="sm"
                        colorScheme="green"
                        focusBorderColor="blue.500"
                        chakraStyles={chakraStyles}
                        getOptionLabel={(op) => op.name}
                        getOptionValue={(option) => option.id}
                        closeMenuOnSelect={false}
                      />
                    )}
                  />
                </Box>
              </Flex>
              <Flex justify="center" align="center" gap="8px" mt={3}>
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<RxReset />}
                    variant="primary"
                    w="88px"
                    h="33px"
                    onClick={() => {
                      resetSearch();
                      setValueSearch("roleSearch", null);
                    }}
                  >
                    Reset
                  </Button>
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<BsSearch />}
                    variant="primary"
                    w="88px"
                    h="33px"
                    type="submit"
                  >
                    Search
                  </Button>
                </Box>
              </Flex>
            </form>
          </Card>
          <SearchTable2
            tableData={newUser}
            columnsData={columnsDataUser}
            totalRow={totalRows.users}
            loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
            listPermissions={[39]}
            actionsActive={["resetPassword", "detail", "edit"]}
            isFetching={isFetching}
            edit={edit}
            resetPassWord={resetPassWord}
            showDetail={showDetail}
            refreshPagingDefault={refreshPagingDefault}
          />
        </CardBody>
      </Card>
    </Flex>
  );
}

export default DataTablesUser;
