import {
    Box,
    Button,
    Flex,
    FormLabel,
    Select,
    Text,
    useColorModeValue,
    Table,
    Thead,
    Tbody,
    Td,
    Tr,
    Th,
    Spinner
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useForm, Controller } from "react-hook-form";
import { BsSearch } from "react-icons/bs";
import { RxReset } from "react-icons/rx";
import DatePickerInput from "components/CustomDateInput/CustomDateInput";
import moment from "moment";
import {
    Select as ReactSelect,
} from "chakra-react-select";

import { useReportActions } from "hooks/useReportActions";
import { useBookingActions } from "hooks/useBookingActions";
import { useUserActions } from "hooks/useUserActions";

import {
    listShowAtom,
    venueListAtom,
    showTimeListAtom,
    listUnitAtom,
    zoneListAtom,
    paymentStatusListAtom,
    listChannelAtom
} from "../../state/show";
import {
    listTicketboxReportAtom
} from "../../state/report";
import {
    usersAtom
} from "../../state/users";
import { authAtom } from "state/auth";

function DataTableTicketboxReport() {
    const chakraStyles = {
        dropdownIndicator: (provided, state) => ({
            ...provided,
            p: 0,
            w: "20px",
        }),
        container: (provided, state) => ({
            ...provided,
            minWidth: "200px",
            maxWidth: "400px"
        }),
        control: (provided) => ({
            ...provided,
            width: '100%',
            minWidth: '200px',
        }),
    };
    const bookingStatus = [
        { Id: "0", Name: "All" },
        { Id: "1", Name: "Success" },
        { Id: "2", Name: "Cancel" },
    ];

    const auth = useRecoilValue(authAtom);
    const listTicketboxReport = useRecoilValue(listTicketboxReportAtom);
    const showTimeList = useRecoilValue(showTimeListAtom);
    const listUnit = useRecoilValue(listUnitAtom);
    const listZone = useRecoilValue(zoneListAtom);
    const listPaymentStatus = useRecoilValue(paymentStatusListAtom);
    const listChannel = useRecoilValue(listChannelAtom);
    const listUsers = useRecoilValue(usersAtom);
    const sortedUsers = [...listUsers].sort((a, b) =>
        a.fullname.localeCompare(b.fullname)
    );

    const reportActions = useReportActions();
    const userActions = useUserActions();
    const bookingActions = useBookingActions();

    const {
        register: registerSearch,
        handleSubmit: handleSubmitSearch,
        control: controlSearch,
        watch: watchSearch,
        reset: resetSearch,
        setValue: setValueSearch,
        formState,
    } = useForm();
    const channelSearch = watchSearch("channelSearch");

    useEffect(() => {
        if (channelSearch) {
            bookingActions.getListUnits({ channel_id: channelSearch }, 0, "");
        }
    }, [channelSearch]);

    const textColor = useColorModeValue("gray.700", "white");
    const listShow = useRecoilValue(listShowAtom);
    const venueList = useRecoilValue(venueListAtom);
    const [isFetching, setIsFetching] = useState(true);

    const { errors, isSubmitting } = formState;

    useEffect(async () => {
        setIsFetching(true);

        bookingActions.getListShow({ status: 1 }, 0, "");
        bookingActions.getListVenue({ status: 1 }, 0, "");
        bookingActions.getListChannel({ status: 1 }, 0, "");
        bookingActions.getListPaymentStatus({ status: 1 }, 0, "");
        bookingActions.getListPaymentMethod({ status: 1 }, 0, "");
        bookingActions.getListUnits({});
        bookingActions.getListShowTime({ status: 1 }, 0, "");
        bookingActions.getListZones({ status: 1 }, 0, "");
        userActions.getAllUsers({ status: 1 }, 0, "");
        setValueSearch("paid_date", new Date());
        await reportActions.getTicketboxReport(
            {
                paid_date: new Date(
                    watchSearch("paid_date").setHours(0, 0, 0, 0)
                ).toISOString(),
            }
        );
        setIsFetching(false);
    }, []);

    async function onSubmitSearch({
        showSearch,
        venueSearch,
        showTimeSearch,
        channelSearch,
        unitSearch,
        userSearch,
        zoneSearch,
        paymentStatusSearch,
        statusSearch
    }) {
        setIsFetching(true);
        const params = new URLSearchParams();
        if (showSearch !== undefined) params.append("show_id", showSearch);
        if (venueSearch !== undefined) params.append("venue_id", venueSearch);
        if (watchSearch("paid_date") !== undefined) {
            params.append(
                "paid_date",
                new Date(watchSearch("paid_date").setHours(0, 0, 0, 0)).toISOString()
            );
        }
        if (showTimeSearch !== undefined) params.append("show_time", showTimeSearch);
        if (channelSearch !== undefined) params.append("channel_id", channelSearch);
        if (unitSearch !== undefined) params.append("unit_id", unitSearch);
        if (zoneSearch !== undefined) params.append("zone_id", zoneSearch);
        if (paymentStatusSearch !== undefined) params.append("payment_status", paymentStatusSearch)
        if (statusSearch !== undefined) params.append("status", statusSearch)

        if (userSearch) {
            userSearch.forEach(user => {
                params.append("user_id", user.id);
            });
        }

        await reportActions.getTicketboxReport(params);
        setIsFetching(false);
    }

    const formattedNumber = (number) => {
        return number.toLocaleString('vi-VN');
    };

    return (
        <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
            <Card px="0px">
                <CardHeader px="22px" mb="12px">
                    <Flex direction="column" display="block">
                        <Text
                            float="left"
                            color={textColor}
                            fontSize="lg"
                            fontWeight="bold"
                            mb="6px"
                        >
                            Ticketbox Report
                        </Text>
                    </Flex>
                </CardHeader>
                <CardBody>
                    <Flex ml="22px"></Flex>
                    <Card>
                        <form
                            id="booking-search-form"
                            onSubmit={handleSubmitSearch(onSubmitSearch)}
                        >
                            <Flex
                                direction="row"
                                alignItems="center"
                                mb={3}
                                gap={4}
                                flexWrap={"wrap"}
                            >
                                <Box direction="row" alignItems="center" display="block">
                                    <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                                        Show
                                    </FormLabel>
                                    <Select
                                        ms="4px"
                                        type="text"
                                        defaultValue="0"
                                        size="sm"
                                        id="showSearch"
                                        name="showSearch"
                                        {...registerSearch("showSearch")}
                                        width="100px"
                                    >
                                        <option value="">All</option>
                                        {listShow.map((item, index) => (
                                            <option key={index} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </Select>
                                </Box>
                                <Box direction="row" alignItems="center" display="block">
                                    <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                                        Venue
                                    </FormLabel>
                                    <Select
                                        ms="4px"
                                        type="text"
                                        size="sm"
                                        id={"venueSearch"}
                                        name="venueSearch"
                                        {...registerSearch("venueSearch")}
                                    >
                                        <option value="">All</option>
                                        {venueList.map((i) => (
                                            <option key={i.id} value={i.id}>
                                                {i.name}
                                            </option>
                                        ))}
                                    </Select>
                                </Box>
                                <Box direction="row" alignItems="center" display="block">
                                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                                        Paid Date
                                    </FormLabel>
                                    <Controller
                                        name="paid_date"
                                        control={controlSearch}
                                        render={({ field }) => (
                                            <DatePickerInput
                                                onChange={(value) => field.onChange(value)}
                                                mask="99/99/9999"
                                                selected={watchSearch("paid_date")}
                                                minDate={new Date("01/01/2020")}
                                            />
                                        )}
                                    />
                                </Box>
                                <Box direction="row" alignItems="center" display="block">
                                    <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                                        Show Time
                                    </FormLabel>
                                    <Select
                                        ms="4px"
                                        type="text"
                                        defaultValue=""
                                        size="sm"
                                        name="showTimeSearch"
                                        {...registerSearch("showTimeSearch")}
                                    >
                                        <option value="">All</option>
                                        {showTimeList.map((item, index) => (
                                            <option key={index} value={item.name}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </Select>
                                </Box>
                                <Box direction="row" alignItems="center" display="block">
                                    <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                                        Channel
                                    </FormLabel>
                                    <Select
                                        ms="4px"
                                        type="text"
                                        size="sm"
                                        name="channelSearch"
                                        {...registerSearch("channelSearch")}
                                        width="200px"
                                    >
                                        <option value="">All</option>
                                        {listChannel.map((i) => (
                                            <option key={i.id} value={i.id}>
                                                {i.name}
                                            </option>
                                        ))}
                                    </Select>
                                </Box>
                                {channelSearch && (
                                    <Box direction="row" alignItems="center" display="block">
                                        <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                                            Unit
                                        </FormLabel>
                                        <Select
                                            ms="4px"
                                            type="text"
                                            size="sm"
                                            id="unit_search"
                                            name="unitSearch"
                                            {...registerSearch("unitSearch")}
                                        >
                                            <option value="">All</option>
                                            {listUnit.map((i) => (
                                                <option key={i.id} value={i.id}>
                                                    {i.name}
                                                </option>
                                            ))}
                                        </Select>
                                    </Box>
                                )}
                                <Box direction="row" alignItems="center" display="block">
                                    <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                                        Person In Charge
                                    </FormLabel>

                                    <Controller
                                        name="userSearch"
                                        control={controlSearch}
                                        render={({ field }) => (
                                            <ReactSelect
                                                {...field}
                                                focusBorderColor="blue.500"
                                                chakraStyles={chakraStyles}
                                                options={sortedUsers}
                                                placeholder="Select PIC"
                                                getOptionLabel={(i) => i.fullname}
                                                getOptionValue={(option) => option.id}
                                                size="sm"
                                                closeMenuOnSelect={true}
                                                isMulti={true}
                                            />
                                        )}
                                    />
                                </Box>
                                <Box direction="row" alignItems="center" display="block">
                                    <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                                        Zone
                                    </FormLabel>
                                    <Select
                                        ms="4px"
                                        type="text"
                                        size="sm"
                                        name="zoneSearch"
                                        {...registerSearch("zoneSearch")}
                                        width="200px"
                                    >
                                        <option value="">All</option>
                                        {listZone.map((i) => (
                                            <option key={i.id} value={i.id}>
                                                {i.name}
                                            </option>
                                        ))}
                                    </Select>
                                </Box>
                                <Box direction="row" alignItems="center" display="block">
                                    <FormLabel
                                        htmlFor="role-search-lbl"
                                        fontWeight="semibold"
                                        fontSize="xs"
                                        ms="4px"
                                    >
                                        Payment Status
                                    </FormLabel>
                                    <Select
                                        ms="4px"
                                        type="text"
                                        defaultValue=""
                                        size="sm"
                                        name="paymentStatusSearch"
                                        {...registerSearch("paymentStatusSearch")}
                                        width="100px"
                                    >
                                        <option value="">All</option>
                                        {listPaymentStatus.map((item, index) => (
                                            <option key={index} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </Select>
                                </Box>
                                <Box direction="row" alignItems="center" display="block">
                                    <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                                        Booking Status
                                    </FormLabel>
                                    <Select
                                        ms="4px"
                                        type="text"
                                        defaultValue="0"
                                        size="sm"
                                        id="statusSearch"
                                        name="statusSearch"
                                        {...registerSearch("statusSearch")}
                                        width="100px"
                                    >
                                        {bookingStatus.map((item, index) => (
                                            <option key={index} value={item.Id}>
                                                {item.Name}
                                            </option>
                                        ))}
                                    </Select>
                                </Box>
                            </Flex>
                            <Text color="red" fontSize={14}>
                                {Object.values(errors)[0]?.message}
                            </Text>
                            <Flex justify="center" align="center" gap="8px" mt={3}>
                                <Box direction="row" alignItems="center" display="block">
                                    <Button
                                        leftIcon={<RxReset />}
                                        variant="primary"
                                        w="88px"
                                        h="33px"
                                        onClick={() => {
                                            resetSearch();
                                            setValueSearch("paid_date", new Date());
                                        }}
                                    >
                                        Reset
                                    </Button>
                                </Box>
                                <Box direction="row" alignItems="center" display="block">
                                    <Button
                                        leftIcon={<BsSearch />}
                                        variant="primary"
                                        w="88px"
                                        h="33px"
                                        type="submit"
                                    >
                                        Search
                                    </Button>
                                </Box>
                            </Flex>
                        </form>
                    </Card>

                    {isFetching ? (
                        <Flex justify="center" align="center" my="20px">
                            <Spinner
                                textAlign="center"
                                thickness="4px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="blue.500"
                                size="xl"
                            />
                        </Flex>
                    ) : (
                        <>
                            < Table size="sm">
                                <Thead>
                                    <Tr>
                                        <Th color="white" colSpan="5" backgroundColor="blue.600">
                                            DAILY REPORT
                                        </Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr key={'index'}>
                                        <Td w={"150px"}>Report date:</Td>
                                        <Td>{moment(new Date(
                                            watchSearch("paid_date")
                                        )).format("DD/MM/YYYY")}</Td>
                                    </Tr>
                                    <Tr key={'index1'}>
                                        <Td>Exported date:</Td>
                                        <Td>{moment(new Date()).format("DD/MM/YYYY")}</Td>
                                    </Tr>
                                    <Tr key={'index2'}>
                                        <Td>Exported by:</Td>
                                        <Td>{auth.email}</Td>
                                    </Tr>
                                </Tbody>
                            </Table>

                            <Table size="sm" key={'reportIndex'} style={{ marginTop: 40, borderCollapse: 'collapse' }} border="1">
                                <Thead>
                                    <Tr>
                                        <Th w={"450px"} style={{ border: '1px solid #f2f2f2' }}></Th>
                                        <Th textAlign="center" style={{ border: '1px solid #f2f2f2' }}>CASH</Th>
                                        <Th textAlign="center" style={{ border: '1px solid #f2f2f2' }}>CC AGR</Th>
                                        <Th textAlign="center" style={{ border: '1px solid #f2f2f2' }}>CC VCB</Th>
                                        <Th textAlign="center" style={{ border: '1px solid #f2f2f2' }}>CC BIDV</Th>
                                        <Th textAlign="center" style={{ border: '1px solid #f2f2f2' }}>BANK TRANSFER</Th>
                                        <Th textAlign="center" style={{ border: '1px solid #f2f2f2' }}>OTHERS</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {listTicketboxReport.dataTable.map((row) => (
                                        <Tr key={row.key}>
                                            <Td style={{ border: '1px solid #f2f2f2' }}>{row.label}</Td>
                                            {row.isMerged ? (
                                                <Td colSpan={6} textAlign={'right'} style={{ border: '1px solid #f2f2f2' }}>{formattedNumber(row.total)}</Td>
                                            ) : (
                                                <>
                                                    <Td textAlign={'right'} style={{ border: '1px solid #f2f2f2' }}>{formattedNumber(row.cash)}</Td>
                                                    <Td textAlign={'right'} style={{ border: '1px solid #f2f2f2' }}>{formattedNumber(row.ccAGR)}</Td>
                                                    <Td textAlign={'right'} style={{ border: '1px solid #f2f2f2' }}>{formattedNumber(row.ccVCB)}</Td>
                                                    <Td textAlign={'right'} style={{ border: '1px solid #f2f2f2' }}>{formattedNumber(row.ccBIDV)}</Td>
                                                    <Td textAlign={'right'} style={{ border: '1px solid #f2f2f2' }}>{formattedNumber(row.bankTransfer)}</Td>
                                                    <Td textAlign={'right'} style={{ border: '1px solid #f2f2f2' }}>{formattedNumber(row.others)}</Td>
                                                </>
                                            )}
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </>
                    )}



                    <div
                        className="content"
                        dangerouslySetInnerHTML={{ __html: listTicketboxReport.list }}
                    ></div>
                </CardBody>
            </Card>
        </Flex >
    );
}

export default DataTableTicketboxReport;
