import { atom } from "recoil";

const pagingAtom = atom({
  key: "paging",
  default: {
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: null,
  },
});

export { pagingAtom };
