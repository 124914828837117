
export const bookingStatus = [
  { Id: "0", Name: "All" },
  { Id: "1", Name: "Success" },
  { Id: "2", Name: "Cancel" },
  { Id: "3", Name: "Unpaid" },
];

export const invoiceStatus = [
  { Id: "-1", Name: "All" },
  { Id: "1", Name: "Publish" },
  { Id: "0", Name: "Unpublish" },
];

export const HOURS = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];
export const MINUTES = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
];

export const STATUS = [
  { id: "0", name: "All" },
  { id: "1", name: "Active" },
  { id: "2", name: "InActive" },
];
