import { atom } from "recoil";

const usersAtom = atom({
  key: "users",
  default: [],
});

const unitsUserAtom = atom({
  key: "unitsUser",
  default: [],
});
const subAccountAtom = atom({
  key: "subAccount",
  default: [],
});
export { usersAtom, unitsUserAtom, subAccountAtom };
