import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Switch,
  Stack,
  Grid,
  Select,
  Image
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import SearchTable2 from "components/Tables/SearchTable2";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { totalRow } from "state/roles-permissions";
import { AddIcon } from "@chakra-ui/icons";
import { checkForbidden } from "helpers/checkForbidden";
import { useCMSActions } from "hooks/useCMS";
import Editor from "components/Editor/Editor2";
import { columnsDataShowReview } from "variables/columnsData";
import { showWebAtom, showReviewAtom } from "state/cms";
import { useRatePlanActions } from "hooks/useRatePlanActions";

function DataTableShowReview() {
  const textColor = useColorModeValue("gray.700", "white");
  const listNews = useRecoilValue(showReviewAtom);
  const totalRows = useRecoilValue(totalRow);
  const listLocationWeb = useRecoilValue(showWebAtom);
  const cmsActions = useCMSActions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCreate, setIsCreate] = useState(true);
  const [currentNewsID, setCurrentNewsID] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [newListNews, setNewListNews] = useState([]);
  const ratePlanActions = useRatePlanActions();
  const [previewImage, setPreviewImage] = useState("");

  const listType = [
    {
      id: 1,
      name: "TripAdvisor",
    },
    {
      id: 2,
      name: "Facebook",
    },
    {
      id: 3,
      name: "Guestbook",
    },
    {
      id: 4,
      name: "Instagram",
    },
  ];
  const validationSchema = Yup.object().shape({
    name: Yup.string(),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    control,
    watch,
  } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const toast = useToast();
  useEffect(() => {
    cmsActions.getListShow();
  }, []);
  const imageURL = watch("image_url");

  useEffect(async () => {
    if (imageURL && imageURL?.length > 0) {
      const formData = new FormData();
      formData.append("file", imageURL[0]);
      const {
        data: { data },
      } = await ratePlanActions.uploadImageRatePlanConfig(formData);
      setPreviewImage(data.url);
    }
  }, [imageURL]);
  useEffect(() => {
    if (listNews) {
      const toolTypes = listNews.map((i) => ({
        ...i,
        filters: { name: "" },
        typeSwitch: "showReview",
      }));
      setNewListNews(toolTypes);
    }
  }, [listNews]);
  const recallApi = async (fetchDataOptions) => {
    setIsFetching(true);
    await cmsActions.getListShowReview(
      {
        name: "",
        sort_type: fetchDataOptions.sort_type,
        sort_field: fetchDataOptions.sort_field,
      },
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
    setIsFetching(false);
  };
  async function onSubmit({
    show_id,
    description,
    type,
    rating,
    isActive,
    name,
  }) {
    const params = {
      name,
      description,
      type: parseInt(type),
      rating: parseInt(rating),
      status: isActive ? 1 : 2,
      show_id: parseInt(show_id),
      image_url: previewImage,
    };
    try {
      let res;
      if (isCreate) {
        res = await cmsActions.createShowReview(params);
      } else {
        res = await cmsActions.updateShowReview(params, currentNewsID);
      }

      if (res.status === 200) {
        onClose();
        reset();
        const message = isCreate
          ? "Create Show Review success"
          : "Update Show Review success";
        toast({
          description: message,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        await cmsActions.getListShowReview(
          {
            name: "",
            status: 1,
          },
          0,
          localStorage && localStorage.getItem("currentPageSizeStore")
        );
      }
    } catch (err) {
      console.warn(err);
      toast({
        description: err.response,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  async function editNews(newsID) {
    const newsData = await cmsActions.getDetailShowReview(newsID);
    if (newsData) {
      const {
        show_id,
        description,
        type,
        rating,
        status,
        name,
        id,
        image_url,
      } = newsData.data.data;
      if (image_url) {
        setPreviewImage(image_url);
      }
      setValue("show_id", show_id);
      setValue("description", description);
      setValue("type", type);
      setValue("rating", rating);
      setValue("name", name);
      setValue("isActive", status === 1 ? true : false);
      setCurrentNewsID(id);
    }
    setIsCreate(false);
    onOpen();
  }

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="12px">
          <Flex direction="column" display="block">
            <Text
              float="left"
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              mb="6px"
            >
              Show Review Management
            </Text>
            <Stack float="right" direction="row" spacing={4}>
              {checkForbidden(42) && (
                <Button
                  size="sm"
                  onClick={() => {
                    onOpen();
                    setIsCreate(true);
                    setValue("description", "");
                  }}
                  leftIcon={<AddIcon />}
                  colorScheme="green"
                  variant="outline"
                >
                  Add Show Review
                </Button>
              )}
            </Stack>
            <Modal
              closeOnOverlayClick={false}
              isOpen={isOpen}
              onClose={() => {
                onClose();
                reset();
              }}
              size="6xl"
            >
              <ModalOverlay />
              <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                  <ModalHeader>
                    {isCreate ? "Add Show Review" : "Edit Show Review"}
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <Grid
                      templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
                      gap="12px"
                      mt={2}
                    >
                      <FormControl>
                        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                          Name
                        </FormLabel>
                        <Input
                          fontSize="sm"
                          ms="4px"
                          type="text"
                          placeholder="Name"
                          maxLength={250}
                          size="md"
                          name="name"
                          {...register("name")}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                          Show
                        </FormLabel>
                        <Select
                          ms="4px"
                          type="text"
                          defaultValue="0"
                          size="sm"
                          name="show_id"
                          {...register("show_id")}
                          width="200px"
                        >
                          <option value={"0"}>Select Show</option>
                          {listLocationWeb.map((i) => (
                            <option key={i.id} value={i.id}>
                              {i.name}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
                      gap="12px"
                      mt={2}
                    >
                      <FormControl>
                        <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                          Type
                        </FormLabel>
                        <Select
                          ms="4px"
                          type="text"
                          defaultValue="0"
                          size="sm"
                          name="type"
                          {...register("type")}
                          width="200px"
                        >
                          {listType.map((i) => (
                            <option key={i.id} value={i.id}>
                              {i.name}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl>
                        <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                          Rating
                        </FormLabel>
                        <Select
                          ms="4px"
                          type="text"
                          defaultValue="0"
                          size="sm"
                          name="rating"
                          {...register("rating")}
                          width="200px"
                        >
                          {[1, 2, 3, 4, 5].map((i) => (
                            <option key={i} value={i}>
                              {i}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <FormControl>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Image
                      </FormLabel>
                      <Input
                        fontSize="sm"
                        ms="4px"
                        type="file"
                        placeholder="Image"
                        size="sm"
                        name="image_url"
                        {...register("image_url")}
                      />
                      {previewImage && (
                        <Image
                          src={previewImage}
                        />
                      )}
                    </FormControl>
                    <FormControl mt={3}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Description
                      </FormLabel>
                      <Editor name="description" control={control} />
                    </FormControl>
                    <FormControl>
                      <Switch
                        id="isChecked"
                        defaultChecked={isCreate && true}
                        name="isActive"
                        {...register("isActive")}
                        size="sm"
                        ms="4px"
                      >
                        Active
                      </Switch>
                    </FormControl>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      colorScheme="blue"
                      mr={3}
                      type="submit"
                      isLoading={isSubmitting}
                    >
                      {isCreate ? "Create" : "Update"}
                    </Button>
                    <Button
                      onClick={() => {
                        onClose();
                        reset();
                      }}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </form>
            </Modal>
          </Flex>
        </CardHeader>
        <CardBody>
          <SearchTable2
            tableData={newListNews}
            columnsData={columnsDataShowReview}
            totalRow={totalRows.showReview}
            edit={editNews}
            actionsActive={["edit"]}
            listPermissions={[43]}
            isFetching={isFetching}
            loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
          />
        </CardBody>
      </Card>
    </Flex>
  );
}

export default DataTableShowReview;
